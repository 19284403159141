
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import React from "react";
import { toast } from 'react-toastify';
import { Box, Button, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, IconButton, MenuItem, Paper, Radio, RadioGroup, Select, Typography } from "@material-ui/core";
import { API, Auth } from 'aws-amplify';
import { CognitoUser } from "amazon-cognito-identity-js";
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { useDispatch, useSelector } from 'react-redux'
import { Address, CheckoutOrder, Order, OrderActions, OrderAttachedItem, OrderItem, OrderStatus, SetCheckoutOrderAction, SET_ADDRESS, SET_CHECKOUT_ORDER, ShippingOffering, UpdateCheckoutOrderAddressAction, UpdateCheckoutOrderIDAction, UpdateCheckoutOrderShippingOfferAction, UPDATE_CHECKOUT_ORDER_ADDRESS, UPDATE_CHECKOUT_ORDER_ID, UPDATE_CHECKOUT_ORDER_SHIPPING_OFFER } from 'redux/order/types';
import { add } from 'lodash';
import { RootState } from 'redux/store';
import cardStyle from 'assets/jss/material-kit-react/components/cardStyle';
import { CheckoutPageView } from '../types';
import { EmptyCartAction, EMPTY_CART } from 'redux/cart/types';

enum AddressView {
  LOADING,
  LIST,
  ADD
};

enum AddAddressState {
  EDIT,
  SAVING
};

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: '1.3em'
  },
  spinner: {
    position: 'relative',
    left: '50%'
  },
  addressText: {
    padding: 0,
    margin: 0,
    fontSize: '90%'
  }
}));

type SearchAddress = {
  address_id?: string | undefined,
  recipient_name?: string | undefined,
  recipient_cell_number?: string | undefined,
  street_name?: string | undefined,
  complex_building?: string | undefined,
  suburb?: string | undefined,
  city_town?: string | undefined,
  province?: string | undefined,
  postal_code?: string | undefined
}

interface PlaceDetailsType {
  long_name: string,
  short_name: string,
  types: string[]
}

interface PlaceType {
  description: string,
  place_id: string,
  structured_formatting: {
    main_text: string;
    secondary_text: string;

    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}

export default function AddressComponent(props: {setStep: (step: number) => void}) {
  const classes = useStyles();
  const [autoCompleteAddress, setAutocompleteAddress] = React.useState<PlaceType | null>(null);
  const [addressSearch, setAddressSearch] = React.useState<SearchAddress>();
  const [selectedAddress, setSelectedAddress] = React.useState<Address | undefined>(useSelector((state: any) => state.order.address));
  const [selectedAddressId, setSelectedAddressId] = React.useState<string>('');
  const [shippingOffering, setShippingOffering] = React.useState<ShippingOffering | null>(null);
  const [retrievingShippingOffering, setRetrievingShippingOffering] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const [addressView, setAddressView] = React.useState<AddressView>(AddressView.LIST);
  const [addAddressState, setAddAddressState] = React.useState<AddAddressState>(AddAddressState.EDIT);
  const loaded = React.useRef(false);
  const [savedAddresses, setSavedAddresses] = React.useState<Address[]>();
  const checkoutOrder = useSelector((state: RootState) => state.order.checkout_order);
  const cart = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch();

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyBOximrXfBM3B5OMT7l-pNswlrmKtxpbGM&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string, componentRestrictions: { country: string }, types: string[], fields: string[] }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  let saveAddress = (address: SearchAddress | undefined) => {
    if (address === undefined) {
      return;
    }
    setAddAddressState(AddAddressState.SAVING);
    return Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
        return API.post('IceApi', '/api/address', {
          body: address,
          headers: { Authorization: sessionToken }
        })
          .then((data: Address) => {
            console.log("add contact success!", data);
            toast.success("Address saved", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setAddressView(AddressView.LIST);
            setSavedAddresses(savedAddresses?.concat([data]))
            setAddAddressState(AddAddressState.EDIT);
            selectAddress(data);
          })
          .catch(err => {
            console.error(err);
            toast.error("An error occured attempting to save the address.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      })
  }

  const selectAddress = (address: Address) => {
    calculateShipping(address);
    setSelectedAddressId(address.address_id!!);
    const action: UpdateCheckoutOrderAddressAction = {
      type: UPDATE_CHECKOUT_ORDER_ADDRESS,
      address: address!!
    };
    dispatch(action);
    dispatch({ type: SET_ADDRESS, address: address });
  }

  let getAddresses = () => {
    if (savedAddresses === undefined) {
      Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
          let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
          setAddressView(AddressView.LOADING);
          return API.get('IceApi', '/api/address', {
            headers: { Authorization: sessionToken }
          })
            .then((data: Address[]) => {
              console.log("get addresses success!", data);

              setSavedAddresses(data);
              if (data && data.length === 0) {
                setAddressView(AddressView.ADD);
              } else {
                setAddressView(AddressView.LIST);
                selectAddress(data[0]);
              }
            })
            .catch(err => {
              console.error(err);
              toast.error("An error occured attempting to retrieve saved addresses.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        });
    }
  };

  React.useEffect(() => {
    getAddresses();
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!placesService.current && (window as any).google) {
      placesService.current = new (window as any).google.maps.places.PlacesService(document.createElement('div'));
    }
    if (!placesService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(autoCompleteAddress ? [autoCompleteAddress] : []);
      return undefined;
    }

    fetch({ input: inputValue, componentRestrictions: { country: "za" }, types: ["address"], fields: ["address_components"] }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (autoCompleteAddress) {
          newOptions = [autoCompleteAddress];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [autoCompleteAddress, inputValue, fetch]);

  let deleteAddress = (address: Address | undefined) => {
    console.log(address);
    return Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
        let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
        return API.del('IceApi', '/api/address', {
          body: { address_id: address?.address_id },
          headers: { Authorization: sessionToken }
        })
          .then(data => {
            console.log("Unfiltered: " + JSON.stringify(savedAddresses))
            let filteredAddresses: Address[] | undefined = savedAddresses?.filter((x) => x.address_id != address?.address_id);
            console.log(filteredAddresses);
            if (filteredAddresses?.length === 0) {
              filteredAddresses = undefined;
              setAddressView(AddressView.ADD);
            }
            setSavedAddresses(filteredAddresses);
            setSelectedAddress(undefined);
          })
      })
  };

  const calculateShipping = (address: Address) => {
    setShippingOffering(null);
    setRetrievingShippingOffering(true);
    Auth.currentAuthenticatedUser()
    .then((user: CognitoUser) => {
        let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();

        API.post('IceApi', '/api/shipping_info', {
            body: {
              address_1: address.street_name,
              address_2: address.complex_building,
              suburb: address.suburb,
              state: address.province,
              post_code: address.postal_code
            },
            headers: { Authorization: sessionToken }
        })
        .then((data: {shipment_info: ShippingOffering}) => {
            console.log("Shipping Info", data);
            setShippingOffering(data.shipment_info);
            const action: UpdateCheckoutOrderShippingOfferAction = {
              type: UPDATE_CHECKOUT_ORDER_SHIPPING_OFFER,
              shipping_offering: data.shipment_info
            };
            dispatch(action);
            setRetrievingShippingOffering(false);
        });
    });
  }

  const handleChange = (event: any) => {
    console.log("handleChange", event);
    let address = savedAddresses!!.find(a => a.address_id == event.target.value)!!;
    selectAddress(address);
  };

  const createContactMeOrder = () => {
    Auth.currentAuthenticatedUser()
    .then((user: CognitoUser) => {
        let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
        API.post('IceApi', '/api/order', {
            body: {return_checkout_order: true, order: {...checkoutOrder!.order, status: OrderStatus.AWAITING_SHIPMENT_SUPPORT}},
            headers: { Authorization: sessionToken }
        })
            .then((data: CheckoutOrder) => {
                console.log("contact me success!", data);
                let action: SetCheckoutOrderAction = {
                  type: SET_CHECKOUT_ORDER,
                  order: data
                };
                dispatch(action);
                let emptyCartAction: EmptyCartAction = {type: EMPTY_CART};
                dispatch(emptyCartAction);
                props.setStep(CheckoutPageView.CONFIRMATION);
            })
            .catch(err => {
                console.error(err);
            });
    });
}

  const listAddresses = (
      <><Grid item xs={12} md={12}>
      <Select
        value={selectedAddressId}
        onChange={handleChange}
        disabled={retrievingShippingOffering}
        variant="outlined"
        fullWidth
      >
        {savedAddresses === undefined ? undefined : savedAddresses.map((address) => <MenuItem value={address.address_id} key={address.address_id}>
          <Grid item xs={5} md={5}>
            <Box fontWeight="fontWeightBold">
              {address.recipient_name}
            </Box>
            <Typography className={classes.addressText} color="textPrimary" gutterBottom>
              {address.street_name}
            </Typography>
            {address.complex_building ? <Typography className={classes.addressText} color="textPrimary" gutterBottom>
              {address.complex_building}
            </Typography> : <div></div>}
            <Typography className={classes.addressText} color="textPrimary" gutterBottom>
              {address.suburb}, {address.city_town}, {address.postal_code}
            </Typography>
            <Typography className={classes.addressText} color="textSecondary" gutterBottom>
              {address.recipient_cell_number}
            </Typography>
          </Grid>
        </MenuItem>)}
      </Select>
    </Grid>
    <Grid item xs={12} md={12}>
            <Typography component="div">
              <Box fontWeight="fontWeightBold">
                Shipping Info
              </Box>
            </Typography>
    </Grid>
    <Grid item xs={12} md={12}>
      {selectedAddressId == '' ?
        <Grid item xs={12} md={12}>
                      Please select an address to view shipping cost
        </Grid> :
        shippingOffering === null ?
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <CircularProgress size="2rem" /><br />
            Retrieving Shipment Info
          </Grid> :
          shippingOffering.retrieved ?
          <Grid item xs={12} md={12} style={{paddingLeft: '0.5em'}}>
            <Typography component="div">Cost: R{shippingOffering.shipping_cost}</Typography>
            <Typography component="div">Delivery Date: {shippingOffering.estimated_delivery_date}</Typography>
          </Grid>:
          <Grid container justify="center">
          <Grid item xs={12} md={8} style={{paddingLeft: '0.5em', textAlign: 'center'}}>
            <Typography component="div" style={{marginBottom: '1em'}}>Unfortunately, our courier service is not able to retrieve shipping info for the address selected.</Typography>
            <Typography component="div" style={{marginBottom: '1em'}}>In order to be able to proceed, you will need to use a different address which we can ship to.</Typography>
            <Typography component="div" style={{marginBottom: '1em'}}>In the case that this is not possible, please click on "Contact Me" and Sateria Support will be in contact with you shortly to proceed with your order.</Typography>
            <Button variant="outlined" color="primary" onClick={createContactMeOrder}>
              Contact Me
            </Button>
          </Grid>
          </Grid>}
    </Grid></>);

  const ADD_ADDRESS_STATE_CONTROLS = {
    [AddAddressState.EDIT]: <Grid item xs={12} md={12}>
                              <Box textAlign='right'>
                                <Button onClick={() => setAddressView(AddressView.LIST)} variant="contained" color="default">
                                  Back
                                          </Button>
                                <Button onClick={() => saveAddress(addressSearch)} variant="contained" color="primary">
                                  Save
                                          </Button>
                              </Box>
                            </Grid>,
    [AddAddressState.SAVING]: <Grid item xs={12} style={{textAlign: 'center'}}>
                                  <CircularProgress size="2rem" />
                              </Grid>
  }

  const addAddresses = (<Grid item xs={12} md={12}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <TextField
          label="Recipient Name"
          fullWidth
          value={addressSearch?.recipient_name ? addressSearch.recipient_name : ''}
          onChange={(event) => setAddressSearch({ ...addressSearch, recipient_name: event.target.value })}
        ></TextField>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          label="Recipient Cell Number"
          fullWidth
          value={addressSearch?.recipient_cell_number ? addressSearch.recipient_cell_number : ''}
          onChange={(event) => setAddressSearch({ ...addressSearch, recipient_cell_number: event.target.value })}
        ></TextField>
      </Grid>
      <Grid item xs={12} md={12}>
        <input type="hidden" value="something"/>
        <Autocomplete
          id="google-maps-search"
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.structured_formatting.main_text)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={autoCompleteAddress}
          onChange={(event: any, newValue: PlaceType | null) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setAutocompleteAddress(newValue);

            if (newValue != null) {
              if (placesService == null || placesService.current == null) {
                return;
              }

              (placesService.current as any).getDetails({ placeId: newValue.place_id, fields: ["address_components"] },
                (result: { address_components: PlaceDetailsType[] }, status) => {
                  console.log(JSON.stringify(result));
                  console.log(JSON.stringify(status));
                  let components = result.address_components;
                  let streetName = components.find((x) => x.types.includes("street_number"))?.long_name;
                  if (streetName != undefined) {
                    streetName = `${streetName} ${components.find((x) => x.types.includes("route"))?.long_name}`;
                  }
                  setAddressSearch({
                    ...addressSearch,
                    street_name: streetName,
                    complex_building: undefined,
                    suburb: components.find((x) => x.types.some(t => t.includes("sublocality")))?.long_name,
                    city_town: components.find((x) => x.types.includes("administrative_area_level_2"))?.long_name,
                    province: components.find((x) => x.types.includes("administrative_area_level_1"))?.long_name,
                    postal_code: components.find((x) => x.types.includes("postal_code"))?.long_name
                  });
                });
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (

            <TextField {...params} label="Street Address" fullWidth inputProps={{...params.inputProps, autoComplete: "new-password"}} />
          )}
          renderOption={(option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length]),
            );

            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          label="Complex / Building (Optional)"
          fullWidth
          value={addressSearch?.complex_building ? addressSearch.complex_building : ''}
          onChange={(event) => setAddressSearch({ ...addressSearch, complex_building: event.target.value })}
        ></TextField>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          label="Suburb"
          fullWidth
          value={addressSearch?.suburb ? addressSearch.suburb : ''}
          onChange={(event) => setAddressSearch({ ...addressSearch, suburb: event.target.value })}
          inputProps={{"autoComplete": "none"}}
        ></TextField>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          label="City / Town"
          fullWidth
          value={addressSearch?.city_town ? addressSearch.city_town : ''}
          onChange={(event) => setAddressSearch({ ...addressSearch, city_town: event.target.value })}
        ></TextField>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          label="Province"
          fullWidth
          value={addressSearch?.province ? addressSearch.province : ''}
          onChange={(event) => setAddressSearch({ ...addressSearch, province: event.target.value })}
        ></TextField>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          label="Postal Code"
          fullWidth
          value={addressSearch?.postal_code ? addressSearch.postal_code : ''}
          onChange={(event) => setAddressSearch({ ...addressSearch, postal_code: event.target.value })}
        ></TextField>
      </Grid>
      {ADD_ADDRESS_STATE_CONTROLS[addAddressState]}
    </Grid>
  </Grid>);

  const ADDRESS_VIEWS = {
    [AddressView.LOADING]:  <Grid item xs={12} style={{textAlign: 'center'}}>
                                <CircularProgress size="2rem" />
                            </Grid>,
    [AddressView.LIST]: listAddresses,
    [AddressView.ADD]: addAddresses
  }

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={5} md={5}>
          <Typography component="div">
            <Box fontWeight="fontWeightBold">
              Delivery Address
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={7} md={7}>
          <Box textAlign='right'>
            <Button onClick={() => setAddressView(AddressView.ADD)} variant="contained" color="primary">
              Add Address
            </Button>
          </Box>
        </Grid>
        {ADDRESS_VIEWS[addressView]}
      </Grid>
    </div>
  );
}