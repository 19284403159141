import { RouteProps } from "react-router-dom";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/store";
import { ProtectedRouteBase } from "./protected-route-base";

const mapState = (state: RootState) => ({
    isLoggedIn: state.auth.isLoggedIn,
    authUser: state.auth.user,
    authInitRun: state.auth.authInitRun
});
  
const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = RouteProps & ConnectedProps<typeof connector>;

class ProtectedRouteAdministrators extends ProtectedRouteBase<PropsFromRedux> {
  validateUserGroup(): Boolean {
    let groups: string[] = this.props.authUser!.getSignInUserSession()!.getAccessToken().decodePayload()["cognito:groups"] || []
    return groups.includes("Administrators");
  }
}

export default connector(ProtectedRouteAdministrators);