import { Auth, API } from "aws-amplify";
import React, { Component } from "react";
import Hidden from '@material-ui/core/Hidden';
import { RootState } from "../../../redux/store";
import { CognitoUser } from "amazon-cognito-identity-js";

import { connect, ConnectedProps } from "react-redux";
import { Alert, Contact, Device } from "../common/models";
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, InputLabel, CircularProgress, Tooltip, Collapse, Chip, Avatar, TextField, Typography, IconButton } from "@material-ui/core";
import HeaderLinks from "components/Header/HeaderLinks";
import Header from "components/Header/Header";
import { SetContactsAction, SET_CONTACTS } from "redux/contact/types";
import { DeviceContact, DeviceItem, LinkContactAction, LINK_CONTACT, SetDevicesAction, SET_DEVICES, UnlinkContactAction, UNLINK_CONTACT, UpdateAlertsAction, UpdateDeviceAction, UPDATE_ALERTS, UPDATE_DEVICE } from "redux/device/types";
import { StringValueTableCell } from "components/common/components/StringValueTableCell/StringValueTableCell";
import AlertDashboard from "./alert-dashboard";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Styles } from "@material-ui/core/styles/withStyles";
import BatteryStdRoundedIcon from '@material-ui/icons/BatteryStdRounded';
import HistoryIcon from '@material-ui/icons/History';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import Divider from '@material-ui/core/Divider';
import SideMenu from "../common/side-menu";
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import EditableDisplayTextField from "components/common/components/EditableDisplayTextField/EditableDisplayTextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toast } from 'react-toastify';
import { BatteryAlertRounded, ExpandLessRounded, ExpandMoreRounded } from "@material-ui/icons";
import InfoIcon from '@material-ui/icons/Info';

const mapState = (state: RootState) => ({
    authUser: state.auth.user,
    contactsInitialised: state.contacts.initialised,
    defaultContacts: state.contacts.contacts.filter(c => c.contact.default == "true").map(c => c.contact),
    optionalContacts: state.contacts.contacts.filter(c => c.contact.default == "false").map(c => c.contact),
    devicesInitialised: state.devices.devicesInitialised,
    devices: state.devices.devices,
    contacts: state.contacts.contacts
});

const mapDispatch = {
    setContacts: (contacts: Contact[]): SetContactsAction => ({ type: SET_CONTACTS, contacts: contacts }),
    setDevices: (devices: Device[]): SetDevicesAction => ({ type: SET_DEVICES, devices: devices }),
    linkDeviceContact: (deviceContact: DeviceContact): LinkContactAction => ({ type: LINK_CONTACT, deviceContact: deviceContact }),
    unlinkDeviceContact: (deviceContact: DeviceContact): UnlinkContactAction => ({ type: UNLINK_CONTACT, deviceContact: deviceContact }),
    updateDevice: (device: Device): UpdateDeviceAction => ({ type: UPDATE_DEVICE, device: device }),
    updateAlerts: (alerts: Alert[], deviceId: string): UpdateAlertsAction => ({ type: UPDATE_ALERTS, alerts: alerts, deviceId: deviceId })
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type DevicePageState = {
    selectionContact: string | null,
    devicesState: DevicesState,
    deviceExtraInfoChecked: Map<string, boolean>,
    deviceAlertsSelected: Map<string, boolean>
    isDisabled: Map<string, boolean>
}

enum DevicesState {
    VIEW_DEVICE_TABLE,
    EDIT_CONTACTS,
    VIEW_DEVICE_DASHBOARD
}

const styles: Styles<any, any, string> = theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    paper: {
        padding: '1.3em'
    },
    spinner: {
        position: 'fixed',
        top: '50%',
        left: '50%'
    },
    successIcon: {
        color: 'green'
    },
    innerDetailGrid: {
        padding: '0.5em'
    },
    fields: {
        padding: 10,
        width: '25ch'
    }
});

class DevicesPage extends Component<PropsFromRedux & WithStyles<any>, DevicePageState> {

    constructor(props: PropsFromRedux & WithStyles<any>) {
        super(props);
        this.state = {
            devicesState: DevicesState.VIEW_DEVICE_TABLE,
            selectionContact: null,
            deviceExtraInfoChecked: new Map<string, boolean>(),
            isDisabled: new Map<string, boolean>(),
            deviceAlertsSelected: new Map<string, boolean>()
        };
    }

    componentDidMount() {
        this.retrieveContactsDevices();
    }

    getAlerts(deviceId: string): Promise<void> {
        return Auth.currentAuthenticatedUser()
            .then((user: CognitoUser) => {
                let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
                return API.get("IceApi", "/api/alerts/" + deviceId, { headers: { Authorization: sessionToken } })
                    .then((data) => {
                        this.props.updateAlerts(data, deviceId);
                    })
                    .catch(err => {
                        toast.error("An error occured attempting to retrieve alerts. Please try again.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.props.updateAlerts([], deviceId);
                    });

            });
    }

    linkContact(deviceId: string, contactId: string): Promise<void> {
        return Auth.currentAuthenticatedUser()
            .then((user: CognitoUser) => {
                let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
                API.post('IceApi', '/api/device_contact', {
                    body: { device_id: deviceId, contact_id: contactId },
                    headers: { Authorization: sessionToken }
                })
                    .then(data => {
                        console.log("success!", data.message);
                        toast.success("Contact added to device", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.props.linkDeviceContact(data.message);
                        this.state.isDisabled.set(contactId, false);
                        this.setState({ isDisabled: this.state.isDisabled })
                    })
                    .catch(err => {
                        console.error(err);
                        toast.error("An error occured attempting to add contact. Please try again.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.state.isDisabled.set(contactId, false);
                        this.setState({ isDisabled: this.state.isDisabled })
                    });
            })
            .catch(err => console.log("Error getting authenticated user.", err));
    }

    unlinkContact(deviceContact: DeviceContact) {
        console.log("unlinkContact", deviceContact);

        Auth.currentAuthenticatedUser()
            .then((user: CognitoUser) => {
                let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
                API.del('IceApi', '/api/device_contact', {
                    body: { device_contact_id: deviceContact.device_contact_id },
                    headers: { Authorization: sessionToken }
                })
                    .then(data => {
                        console.log("success!", data.message);
                        this.props.unlinkDeviceContact(deviceContact);
                        toast.success("Contact removed from device", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.state.isDisabled.set(deviceContact.device_id, false);
                        this.setState({ isDisabled: this.state.isDisabled })
                    })

                    .catch(err => {
                        console.error(err);
                        toast.error("An error occured attempting to remove contact. Please try again.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        this.state.isDisabled.set(deviceContact.device_id, false);
                        this.setState({ isDisabled: this.state.isDisabled })

                    });
            })
            .catch(err => console.log("Error getting authenticated user", err));
    }

    setContactForSelection(evt: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) {
        console.log(`setContactForSelection`, evt);
        this.setState({ selectionContact: evt.target.value as string });
    }

    clearContactSelection() {
        console.log(`clearContactSelection`);
        this.setState({ selectionContact: null });
    }

    retrieveContactsDevices() {
        console.log("Calling retrieveContactsDevices");
        Auth.currentAuthenticatedUser()
            .then((user: CognitoUser) => {
                let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
                if (!this.props.devicesInitialised) {
                    API.get("IceApi", "/api/device", {
                        headers: { Authorization: sessionToken }
                    })
                    .then(data => {
                        console.log("/api/device get devices", data);
                        this.props.setDevices(data);
                    })
                    .catch(err => console.error("/api/device get error", err));
                }
                if (!this.props.contactsInitialised) {
                    API.get("IceApi", "/api/contact", {
                        headers: { Authorization: sessionToken }
                    })
                        .then((data: any[]) => {
                            this.props.setContacts(data);
                            console.log("/api/contact get Success!", data);
                        })
                        .catch(err => console.error("/api/contact get Error!", err));
                }
            })
            .catch(err => console.error("currentAuthenticatedUser Error", err));
    }

    updateDevice(device: Device) {
        return Auth.currentAuthenticatedUser()
            .then((user: CognitoUser) => {
                let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
                return API.put('IceApi', '/api/device', {
                    body: { device: device },
                    headers: { Authorization: sessionToken }
                })
                    .then(data => {
                        this.props.updateDevice(data.message);
                    })
            })
    }

    getContactsForDevice(device: DeviceItem): Contact[] {
        let defaultContacts: Contact[] = this.props.defaultContacts;
        let defaultContactIds: string[] = this.props.defaultContacts.map(cnt => cnt.contact_id);
        let deviceContactIds: string[] = device.device.contacts.filter(cnt => !defaultContactIds.includes(cnt.contact_id)).map(cnt => cnt.contact_id);
        return defaultContacts.concat(this.props.contacts.filter(cnt => deviceContactIds.includes(cnt.contact.contact_id)).map(cnt => cnt.contact));
    }

    toggleDeviceInfoState(device_id: string) {
        let checked: boolean | undefined = this.state.deviceExtraInfoChecked.get(device_id);
        if (checked) {
            this.state.deviceExtraInfoChecked.set(device_id, false);
        } else {
            this.state.deviceExtraInfoChecked.set(device_id, true);
        }

        this.setState({ deviceExtraInfoChecked: this.state.deviceExtraInfoChecked })
    }

    toggleAlertSelectedState(device_id: string) {
        let checked: boolean | undefined = this.state.deviceAlertsSelected.get(device_id);
        if (checked === true) {
            this.state.deviceAlertsSelected.set(device_id, false);
        } else {
            this.state.deviceAlertsSelected.set(device_id, true);
            let deviceExpanded: boolean | undefined = this.state.deviceExtraInfoChecked.get(device_id);
            if (deviceExpanded === undefined || deviceExpanded === false) {
                this.state.deviceExtraInfoChecked.set(device_id, true);
            }
        }

        this.setState({ deviceExtraInfoChecked: this.state.deviceExtraInfoChecked, deviceAlertsSelected: this.state.deviceAlertsSelected })
    }

    viewDevicesTable() {
        const { classes } = this.props;
        return (
            <div>
                <Grid container spacing={3}>
                    {this.props.devices.length > 0 ?
                        this.props.devices.map(dev =>
                            <Grid item xs={12} md={12}>
                                <Paper className={classes.paper}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={1} md={1}>
                                            {dev.device.bat_status ? <Tooltip title={"Battery: " + dev.device.bat_status}>
                                                {dev.device.bat_status == "OK" ? <BatteryStdRoundedIcon /> : <BatteryAlertRounded />}
                                            </Tooltip> : <Tooltip title="Battery Level Unknown"><BatteryUnknownIcon /></Tooltip>}
                                        </Grid>
                                        <Grid item xs={1} md={1}>
                                            {dev.device?.last_seen ? <Tooltip title={"Last seen: " + new Date(dev.device.last_seen).toLocaleString()}>
                                                <CloudDoneIcon className={classes.successIcon} />
                                            </Tooltip> : <CloudDoneIcon className={classes.successIcon} />}
                                        </Grid>
                                        <Grid item xs={7} md={8}>
                                            {dev.device.name}
                                        </Grid>
                                        <Grid item xs={1} md={1}>
                                            <Tooltip title={this.state.deviceAlertsSelected.get(dev.device.device_id) === true ? "Device Information" : "Alert History"}>
                                                <IconButton onClick={() => {
                                                    this.toggleAlertSelectedState(dev.device.device_id);
                                                    this.getAlerts(dev.device.device_id);
                                                }}>
                                                    {this.state.deviceAlertsSelected.get(dev.device.device_id) === true ? <InfoIcon /> : <HistoryIcon />}
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>

                                        <Grid item xs={2} md={1}>
                                            {!this.state.deviceExtraInfoChecked.get(dev.device.device_id) ?
                                                <Tooltip title="More Information">
                                                    <IconButton onClick={() => this.toggleDeviceInfoState(dev.device.device_id)}>
                                                        <ExpandMoreRounded />
                                                    </IconButton>
                                                </Tooltip> :
                                                <IconButton onClick={() => this.toggleDeviceInfoState(dev.device.device_id)}>
                                                    <ExpandLessRounded />
                                                </IconButton>
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Collapse in={this.state.deviceExtraInfoChecked.get(dev.device.device_id)}>
                                                <Divider></Divider>
                                                {this.state.deviceAlertsSelected.get(dev.device.device_id) ? <AlertDashboard alerts={dev.alerts}></AlertDashboard> :
                                                    <Grid className={classes.innerDetailGrid} container spacing={1}>
                                                        <Grid item xs={12} md={8}>
                                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                                Device Information
                                        </Typography>
                                                            <Grid className={classes.innerDetailGrid} container spacing={1}>
                                                                <Grid item xs={12} md={6}>
                                                                    <EditableDisplayTextField

                                                                        label="Device Name"
                                                                        value={dev.device.name}
                                                                        updateFunc={(val: string) => {
                                                                            let newDevice = { ...dev.device, name: val };
                                                                            return this.updateDevice(newDevice);
                                                                        }}
                                                                    />
                                                                    <TextField className={classes.fields}
                                                                        size="small"
                                                                        variant="outlined"
                                                                        label="Device ID"
                                                                        defaultValue={dev.device.device_id}
                                                                        InputProps={{
                                                                            readOnly: true,
                                                                        }} />
                                                                </Grid>
                                                                <Grid item xs={12} md={6} >
                                                                    <Grid container spacing={1}>
                                                                        {dev.device.last_seen ?
                                                                            <Grid item xs={12} md={12}><TextField className={classes.fields}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                label="Last Seen"
                                                                                defaultValue={new Date(dev.device.last_seen).toLocaleString()}
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }} /></Grid> : <div />}
                                                                        {dev.device.bat_status ?
                                                                            <Grid item xs={12} md={12}><TextField className={classes.fields}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                label="Battery"
                                                                                defaultValue={dev.device.bat_status + " - " + dev.device.bat_voltage + "v"}
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }} /></Grid> : <div />}
                                                                        {dev.device.temperature ?
                                                                            <Grid item xs={12} md={12} ><TextField className={classes.fields}
                                                                                size="small"
                                                                                variant="outlined"
                                                                                label="Temperature"
                                                                                defaultValue={dev.device.temperature + "℃"}
                                                                                InputProps={{
                                                                                    readOnly: true,
                                                                                }} /></Grid> : <div />}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                                Associated Contacts
                                    </Typography>
                                                            {this.getContactsForDevice(dev).map(cont =>
                                                                <Tooltip title={cont.phone_number}>
                                                                    <Chip
                                                                        label={cont.name}
                                                                        disabled={this.state.isDisabled.get(cont.contact_id)}
                                                                        variant="outlined"
                                                                        avatar={cont.default == "true" ? <Avatar>D</Avatar> : undefined}
                                                                        onDelete={cont.default == "true" ? undefined : () => {
                                                                            this.state.isDisabled.set(cont.contact_id, false);
                                                                            this.setState({ isDisabled: this.state.isDisabled })
                                                                            this.unlinkContact({
                                                                                device_id: dev.device.device_id,
                                                                                device_contact_id: dev.device.contacts.find((dev_cnt) => dev_cnt.contact_id == cont.contact_id)?.device_contact_id,
                                                                                contact_id: cont.contact_id
                                                                            });
                                                                        }}
                                                                    />
                                                                </Tooltip>)}
                                                            {this.props.contacts.length == this.props.contacts.filter((cnt) => this.getContactsForDevice(dev).find((dev_cnt) => dev_cnt.contact_id == cnt.contact.contact_id)).length ? undefined :
                                                                <Autocomplete
                                                                    options={this.props.contacts.filter((cnt) => this.getContactsForDevice(dev).find((dev_cnt) => dev_cnt.contact_id == cnt.contact.contact_id) == null)}
                                                                    getOptionLabel={(option) => option.contact.name}
                                                                    autoComplete
                                                                    disabled={this.state.isDisabled.get(dev.device.device_id)}
                                                                    renderInput={(params) => <TextField {...params} label="Link a contact to this device" margin="normal" />}
                                                                    onChange={(event, value) => {

                                                                        if (value != null) {
                                                                            this.state.isDisabled.set(dev.device.device_id, true);
                                                                            this.setState({ isDisabled: this.state.isDisabled })
                                                                            this.linkContact(dev.device.device_id, value.contact.contact_id);
                                                                        }
                                                                    }}
                                                                    noOptionsText={'All contacts are linked'}
                                                                />
                                                            }
                                                        </Grid>
                                                    </Grid>}
                                            </Collapse>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>) : <CircularProgress className={classes.spinner} color="primary" />}
                </Grid>
            </div>
        );
    }

    editContactsClicked() {
        let state = this.state.devicesState;
        state = state == DevicesState.EDIT_CONTACTS ? DevicesState.VIEW_DEVICE_TABLE : DevicesState.EDIT_CONTACTS;
        this.setState({ devicesState: state });
    }

    viewDeviceDashBoard() {
        this.setState({ devicesState: DevicesState.VIEW_DEVICE_DASHBOARD });
    }

    render() {
        let editingContacts = this.state.devicesState == DevicesState.EDIT_CONTACTS;
        let viewingDeviceDashboard = this.state.devicesState == DevicesState.VIEW_DEVICE_DASHBOARD;
        let viewingDevices = this.state.devicesState == DevicesState.VIEW_DEVICE_TABLE;
        const { classes } = this.props;
        return (
            <div >
                <Header
                    color="white"
                    brand="Sateria"
                    rightLinks={<HeaderLinks />}
                />
                <Grid container spacing={3}>
                    <Grid item xs={1} md={3}></Grid>
                    <Grid item xs={11} md={6}><h2>Devices</h2></Grid>
                    <Hidden smDown>
                        <Grid item md={2}>
                        </Grid>
                        <Grid item md={1}></Grid>


                        <Grid item md={1}></Grid>
                        <Grid item md={2}>
                            <SideMenu></SideMenu>
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid item xs={1}></Grid>
                    </Hidden>

                    <Grid item xs={10} md={8}>
                        {/* {viewingDevices ?
                        this.viewDevicesTable():
                            this.editContactsTable() } */
                            this.viewDevicesTable()}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default connector(withStyles(styles)(DevicesPage));