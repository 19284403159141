/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle";
import { useDispatch, useSelector } from 'react-redux';
import { SET_LOGGED_IN } from "redux/auth/types";
import Button from '@material-ui/core/Button'
import { Auth } from "aws-amplify";
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles(styles);

async function signOut(dispatch){
    await Auth.signOut();
    dispatch({type: SET_LOGGED_IN , isLoggedIn: false})
};

export default function HeaderLinks(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);

  let authLinks = (
    <ListItem className={classes.listItem}>
    <Link to="/auth">
      <Button className={classes.navLink}>
        <AccountCircleOutlinedIcon className={classes.icons} />  Sign In
      </Button>
    </Link>
  </ListItem>
  );

  let portalLinks = undefined;
  
  if (auth.isLoggedIn){
    
    let username = auth.user?.attributes?.name || auth.user?.getUsername();

    authLinks = (
      <ListItem className={classes.listItem}>
         <CustomDropdown
        noLiPadding
        buttonText={username}
        buttonProps={{
          className: classes.navLink,
          color: "transparent"
        }}
        buttonIcon={AccountCircleOutlinedIcon}
        dropdownList={[
          <Link to="" onClick={()=>(signOut(dispatch))} className={classes.dropdownLink}>
            Sign Out
          </Link>,
        ]}
      />
    </ListItem>
    )

    portalLinks = (
      <ListItem className={classes.listItem}>
      <CustomDropdown
        noLiPadding
        buttonText="Components"
        buttonProps={{
          className: classes.navLink,
          color: "transparent"
        }}
        buttonIcon={Apps}
        dropdownList={[
          <Link to="/clientArea/devices" className={classes.dropdownLink}>
            Devices
          </Link>,
          <Link to="/clientArea/contacts" className={classes.dropdownLink}>
            Contacts
          </Link>,
          <Link to="/admin" className={classes.dropdownLink}>
            Admin
        </Link>
        ]}
      />
    </ListItem>
    )
  }

  let productsLinks = (
    <ListItem className={classes.listItem}>
    <CustomDropdown
      noLiPadding
      buttonText="Buttons"
      buttonProps={{
        className: classes.navLink,
        color: "transparent"
      }}
      buttonIcon={SearchIcon}
      dropdownList={[
        <Link to="/product/OgSPFixed" className={classes.dropdownLink}>
          Panic Button Fixed
        </Link>,
        // <Link to="/product/OgSPAdvanced" className={classes.dropdownLink}>
        //   Panic Button Advanced
        // </Link>
      ]}
    />
  </ListItem>);

let cartIcon = cart != undefined && cart.count > 0 ? (
  <ListItem className={classes.listItem}>
    <Link to="/cart">
    <Button
            target="_blank"
            className={classes.navLink}
          >
            <Badge badgeContent={cart?.count} color="secondary">
              <ShoppingCartIcon />
            </Badge>
          </Button>
          </Link>
  </ListItem>) : null ;
  
    return (
      <List className={classes.list}>
        {portalLinks}
        {productsLinks}
        {authLinks}
        {cartIcon}
      </List>
    );
} 