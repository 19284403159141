import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React, { Component } from "react";
import RouterIcon from '@material-ui/icons/Router';
import ContactsIcon from '@material-ui/icons/Contacts';
import { RootState } from "redux/store";
import { connect, ConnectedProps } from "react-redux";
import SettingsIcon from '@material-ui/icons/Settings';

const mapState = (state: RootState) => ({
    isLoggedIn: state.auth.isLoggedIn,
    authUser: state.auth.user,
    authInitRun: state.auth.authInitRun
});
  
const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

function ListItemLink(props:any) {
    return <ListItem button component="a" {...props} />;
}

class SideMenu extends Component<PropsFromRedux> {
    isAdmin(): Boolean {
        let groups: string[] = this.props.authUser!.getSignInUserSession()!.getAccessToken().decodePayload()["cognito:groups"] || []
        return groups.includes("Administrators");
      }

    render() {
        return (
            <div>
                 <List component="nav" aria-label="main">
                            <ListItemLink href="#/clientArea/devices">
                            <ListItemIcon>
                                <RouterIcon />
                            </ListItemIcon>
                            <ListItemText primary="Devices" />
                            </ListItemLink>

                            <ListItemLink href="#/clientArea/contacts">
                            <ListItemIcon>
                                <ContactsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Contacts" />
                            </ListItemLink>
                            {this.isAdmin() ? 
                            <ListItemLink href="#/admin">
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Admin" />
                            </ListItemLink> : <div></div>}
                        </List>
            </div>
        );
    }
}

export default connector(SideMenu);