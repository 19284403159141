import React from 'react';
import ProductInfo from './ProductInfo';

export default class PanicButtonFixedProduct implements ProductInfo {
    monthly_price: number;
    product_id: string;
    device_cost: number;

    constructor(productDetails: {price_per_month: number, product_id: string, device_cost: number}) {
        console.log("PanicButtonFixedProduct", productDetails);
        this.monthly_price = productDetails.price_per_month;
        this.product_id = productDetails.product_id;
        this.device_cost = productDetails.device_cost;
    }
    getMonthlyPrice(): number {
        return this.monthly_price;
    }
    getDeviceCost(): number {
        return this.device_cost;
    }

    getTitle(): string {
        return "0G Panic Button Fixed";
    }

    getProductID(): string {
        return this.product_id;
    }

    getDescription(): string {
        return "The OG Panic Button Fixed device is ideal for scenarios where real time tracking is not needed";
    }

    getUseCases(): JSX.Element {
        return (
            <ul style={{marginTop: 0}}>
                <li>A panic button mounted in your home</li>
                <li>A panic button for a specific unit in a security complex or estate</li>
                <li>Elderly care</li>
            </ul>
        );
    }

    getDeviceInfo(): JSX.Element {
        return (
            <div>
                <div>Dimensions: 5cm x 2cm</div>
                <div>Battery Life: 3 years</div>
            </div>
        );
    }

    getImageURL(): string {
        return "products/panic-button.jpeg";
    }
}