import { Button, ButtonGroup, CircularProgress, Collapse, Grid, Paper, Switch, TextField, Tooltip, Typography, WithStyles } from "@material-ui/core";
import withStyles, { Styles } from "@material-ui/core/styles/withStyles";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { CognitoUser } from "amazon-cognito-identity-js";
import { API, Auth } from "aws-amplify";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import { produce } from "immer";
import React, { ChangeEvent, Component } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import { connect, ConnectedProps } from "react-redux";
import { toast } from 'react-toastify';
import { AddContactAction, ADD_CONTACT, DeleteContactAction, DELETE_CONTACT, SetContactsAction, SET_CONTACTS, UpdateContactAction, UPDATE_CONTACT } from "redux/contact/types";
import { RootState } from "../../../redux/store";
import { Contact, ContactStatus, NotificationChannel } from "../common/models";
import SideMenu from "../common/side-menu";
import ContactCard from "./components/contact-card";
import { DISABLED_NTF_CHANNELS } from "./constants";

const mapState = (state: RootState) => ({
    authUser: state.auth.user,
    contacts: state.contacts.contacts,
    contactsInitialised: state.contacts.initialised
});

const mapDispatch = {
    setContacts: (contacts: Contact[]): SetContactsAction => ({type: SET_CONTACTS, contacts: contacts}),
    addContact: (contact: Contact): AddContactAction => ({type: ADD_CONTACT, contact: contact}),
    updateContact: (contact: Contact): UpdateContactAction => ({type: UPDATE_CONTACT, contact: contact}),
    deleteContact: (contactId: string): DeleteContactAction => ({type: DELETE_CONTACT, contact_id: contactId})
}

const connector = connect(mapState, mapDispatch);

enum TableState {
    VIEW,
    EDIT,
    ADD
}

const styles: Styles<any, any, string> = theme => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row'
    },
    paper: {
      padding: '1.3em'
    },
    spinner: {
        position: 'fixed',
        top: '50%',
        left: '50%' 
    },
    innerDetailGrid: {
        padding: '0.5em'
    },
    fields : {
        width: '100%'
    },
    center: {
        alignSelf: 'center'
    },
    addContactSaveButtonGrid: {
        textAlign: 'end'
    },
    addContactCancelButtonGrid: {
        textAlign: 'start'
    },
    addContactProgressSpinner: {
        textAlign: 'center'
    },
    textFieldLabel: {
        top: 10,
        left: 10
    },
    marginTop1: {
        marginTop: '1em'
    },
    marginTop2: {
        marginTop: '2em'
    },
    phoneControlInput: {
        padding: 10,
        width: '100% !important',
        height: '1.1876em'
    },
    phoneControlContainer : {
        width: '100% !important',
        marginTop: '2em'
    }
  });

  enum AddContactState {
    NotVisible,
    CompleteForm,
    AddingContact,
    ContactAdded
  }

  type PropsFromRedux = ConnectedProps<typeof connector>;

  type ContactsPageProps = PropsFromRedux & WithStyles<any>;

  type ContactsPageState = {
      newContact: Contact, 
      tableState: TableState, 
      contactExtraInfoChecked: {[id: string]: boolean},
      notificationChannelUpdating: {[id: string]: NotificationChannel},
      defaultContactUpdating: string[],
      addContactState: AddContactState
    }

class ContactsPage extends Component<ContactsPageProps, ContactsPageState> {
    constructor(props: ContactsPageProps) {
        super(props);
        this.state = {
            newContact: this.createEmptyContactModel(), 
            tableState: TableState.VIEW, 
            contactExtraInfoChecked: {}, 
            notificationChannelUpdating: {},
            defaultContactUpdating: [],
            addContactState: AddContactState.NotVisible
        };
    }

    componentDidMount() {
        this.retrieveContacts();
    }

    createEmptyContactModel(): Contact {
        return {
            name: "",
            phone_number: "",
            contact_id: "new_contact_id",
            default: "false",
            notificationChannels: {
                [NotificationChannel.SMS]: false,
                [NotificationChannel.WHATSAPP]: false,
                [NotificationChannel.ROBOCALL]: false,
            },
            status: ContactStatus.PENDING_VERIFICATION
        };
    }

    retrieveContacts() {
        console.log("Calling API for retrieveUsers");
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            API.get("IceApi", "/api/contact", {
                headers: {Authorization: sessionToken}
            })
            .then((data: any[]) => {
                this.props.setContacts(data);
                console.log("Success!", data);
            })
            .catch(err => console.error("Error!", err));
        })
        .catch(err => console.log("Error getting authenticated user", err)); 
    }

    addContact() {
        console.log(`addContact Number [${this.state.newContact.phone_number}]`);
        this.setState({addContactState: AddContactState.AddingContact});
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            API.post('IceApi', '/api/contact', {
                body: {contact: this.state.newContact}, 
                headers: {Authorization: sessionToken}})
            .then(data => {
                console.log("success!", data);
                this.props.addContact(data.message);
                this.setState({tableState: TableState.VIEW, addContactState: AddContactState.ContactAdded});
                toast.success("Contact successfully added", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                setTimeout(() => this.setState({addContactState: AddContactState.NotVisible, newContact: this.createEmptyContactModel()}), 2000)
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.log("Error getting authenticated user", err)); 
    }

    updateContactNumber(contact: Contact, number: string) {
        console.log(`updateName ContactID [${contact.contact_id}]`);
        contact.phone_number = number;
        return Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            return API.put('IceApi', '/api/contact', {
                body: {contact: contact}, 
                headers: {Authorization: sessionToken}})
            .then(data => {
                console.log("success!", data);
                this.props.updateContact(data.message);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.log("Error getting authenticated user", err)); 
    }

    discardChanges() {
        console.log("discardChanges");

        this.setState({tableState: TableState.VIEW});
    }

    viewContacts() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.contactsInitialised ?
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Collapse in={this.state.addContactState != AddContactState.NotVisible} timeout={200}>
                                <Paper className={classes.paper}>
                                    {this.state.addContactState != AddContactState.NotVisible ?
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            Add New Contact
                                        </Grid>
                                        <Grid className={classes.innerDetailGrid} container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                    Contact Information
                                                </Typography>
                                                <Grid className={classes.innerDetailGrid} container spacing={1}>
                                                    <Grid item xs={8}>
                                                        <Grid container className={classes.fields}>
                                                            <Grid item xs={6} className={classes.center}>
                                                                Default Contact
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                    <Switch 
                                                                        color="primary"
                                                                        checked={this.state.newContact.default == "true"}
                                                                        onChange={(evt: any, checked: boolean) => this.setState(produce(this.state, draft => {
                                                                            draft.newContact.default = checked.toString();
                                                                        }))} />
                                                            </Grid>
                                                            <TextField className={classes.fields}
                                                                style={{marginTop: '1em'}}
                                                                size="small"
                                                                variant="outlined"
                                                                label="Contact Name"
                                                                value={this.state.newContact.name}
                                                                onChange={(evt: ChangeEvent<HTMLInputElement>) => 
                                                                    this.setState(produce(this.state, draft => {
                                                                        draft.newContact.name = evt.target.value
                                                                    }))} />
                                                            <PhoneInput 
                                                                country='za'
                                                                containerClass={classes.phoneControlContainer}
                                                                inputClass={classes.phoneControlInput}
                                                                masks={{za: '(..) ... ....'}}
                                                                value={this.state.newContact.phone_number}
                                                                onChange={(value: string, data: {dialCode: String}) => 
                                                                    this.setState(produce(this.state, draft => {
                                                                        console.log(data);
                                                                        let newNumber = value.slice(data.dialCode.length);
                                                                        if (newNumber.length > 0 && newNumber.charAt(0) == "0")
                                                                            newNumber = newNumber.slice(1)
                                                                        draft.newContact.phone_number = "+" + data.dialCode + newNumber;
                                                                    }))}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2}></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                    Notification Channels
                                                </Typography>
                                                {Object.keys(NotificationChannel).map(cnl => 
                                                    <Grid className={classes.innerDetailGrid} container xs={12} style={{paddingLeft: '1em'}}>
                                                        <Grid item xs={6} className={classes.center}>
                                                            {NotificationChannel[cnl]}
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            {DISABLED_NTF_CHANNELS.includes(cnl as NotificationChannel) ?
                                                                <Tooltip title="Currently unavailable - Coming soon">
                                                                    <span>
                                                                        <Switch color="primary" disabled={true} />
                                                                    </span>
                                                                </Tooltip> :
                                                                <Switch 
                                                                    color="primary" 
                                                                    checked={this.state.newContact.notificationChannels[cnl as NotificationChannel]}
                                                                    onChange={(evt) => this.setState(produce(this.state, draft => {
                                                                                draft.newContact.notificationChannels[NotificationChannel[cnl]] = evt.target.checked;
                                                                            }))} />}
                                                        </Grid>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            {(() => {
                                                switch(this.state.addContactState) {
                                                    case AddContactState.AddingContact: 
                                                        return (
                                                            <Grid item xs={12} className={classes.addContactProgressSpinner}>
                                                                <CircularProgress size="2rem" />
                                                            </Grid>);
                                                    case AddContactState.CompleteForm:
                                                        return (
                                                            <Grid container xs={12}>
                                                                <Grid item xs={5} className={classes.addContactSaveButtonGrid}>
                                                                    <Button 
                                                                        color="primary" variant="contained"
                                                                        onClick={this.addContact.bind(this)}
                                                                    >Create</Button>
                                                                </Grid>
                                                                <Grid item xs={1}/>
                                                                <Grid item xs={5} className={classes.addContactCancelButtonGrid}>
                                                                    <Button 
                                                                        color="primary" variant="outlined"
                                                                        onClick={() => this.setState({newContact: this.createEmptyContactModel(), addContactState: AddContactState.NotVisible})}
                                                                    >Cancel</Button>
                                                                </Grid>
                                                                <Grid item xs={1}/>
                                                            </Grid>
                                                        );
                                                    case AddContactState.ContactAdded:
                                                        return (
                                                            <Grid item xs={12} className={classes.addContactProgressSpinner}>
                                                                <CheckCircleOutlineIcon style={{ fontSize: '2rem', color: 'green' }}/>
                                                            </Grid>
                                                        );
                                            }})()}
                                        </Grid>
                                    </Grid>:
                                    <div></div>}
                                </Paper>
                            </Collapse>
                        </Grid>
                        {this.props.contacts.map(cnt => 
                            <ContactCard contact={cnt} key={cnt.contact.contact_id}></ContactCard>  
                        )}
                    </Grid>:
                    <Grid container spacing={3}>
                        <CircularProgress className={classes.spinner} color="primary" />
                    </Grid>}
            </div>
        );
    }

    render() {
        return (
            <div>
                <Header
                    color="white"
                    brand="Sateria"
                    rightLinks={<HeaderLinks />}
                /> 
                <Grid container spacing={3}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6}>
                        <h2>Contacts</h2>
                        </Grid> 
                    <Grid item xs={3}>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            <Button 
                                variant="contained"
                                onClick={() => this.setState({addContactState: AddContactState.CompleteForm})}
                                disabled={this.state.addContactState != AddContactState.NotVisible}
                                >Add Contact</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                       <SideMenu />
                    </Grid>
                    <Grid item xs={8}>
                        {this.viewContacts()}
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </div>
        );
    }
}

export default connector(withStyles(styles)(ContactsPage));