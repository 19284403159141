import { produce } from "immer";

import { SET_ADDRESS, OrderActions, OrderState, SET_ORDERS, UPDATE_ORDER, SET_CHECKOUT_ORDER, UPDATE_CHECKOUT_ORDER_ADDRESS, UPDATE_CHECKOUT_ORDER_SHIPPING_OFFER, UPDATE_CHECKOUT_ORDER_ID } from "./types";

const initialState: OrderState = {
    address: undefined,
    checkout_order: null,
    orders: [],
    ordersInitialised: false
}

export function orderReducer(state = initialState, action: OrderActions): OrderState {
    switch (action.type) {
        case SET_ADDRESS:
            return { ...state, address: action.address };
        case SET_ORDERS:
            return {...state, orders: action.orders};
        case UPDATE_ORDER:
            return produce(state, draft => {
                let orderItemToUpdate = draft.orders.find(o => o.order.order_id == action.order.order_id)!;
                orderItemToUpdate.order = {...orderItemToUpdate.order, ...action.order}
            });
        case SET_CHECKOUT_ORDER:
            return produce(state, draft => {
                if (action.order === null) {
                    draft.checkout_order = null;
                    return;
                }
                if (draft.checkout_order == null) {
                    draft.checkout_order = {
                        order: action.order
                    }
                } else {
                    draft.checkout_order.order = {...draft.checkout_order.order, ...action.order};
                }
            });
        case UPDATE_CHECKOUT_ORDER_ADDRESS:
            return produce(state, draft => {
                if (draft.checkout_order == null) return;
                draft.checkout_order.order.address = action.address;
                draft.checkout_order.order.shipping_offering = null;
            });
        case UPDATE_CHECKOUT_ORDER_SHIPPING_OFFER:
            return produce(state, draft => {
                if (draft.checkout_order == null) return;
                draft.checkout_order.order.shipping_offering = action.shipping_offering;
            });
        case UPDATE_CHECKOUT_ORDER_ID:
            return produce(state, draft => {
                if (draft.checkout_order == null) return;
                draft.checkout_order.order.order_id = action.order_id;
            });
        default: return state;
    }
}