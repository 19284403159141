import { DeviceContact } from "redux/device/types";

export enum NotificationChannel {
    SMS = "SMS",
    WHATSAPP = "WHATSAPP",
    ROBOCALL = "ROBOCALL"
}

export enum ContactStatus {
    PENDING_VERIFICATION = "PENDING_VERIFICATION",
    ACTIVE = "ACTIVE"
}

export enum DeviceMode {
    AWAITING_REGISTRATION = "AWAITING_REGISTRATION",
    AWAITING_SHIPMENT = "AWAITING_SHIPMENT",
    AWAITING_ACTIVATION = "AWAITING_ACTIVATION",
    CUSTOMER_LIVE = "CUSTOMER_LIVE",
    CUSTOMER_TESTING = "CUSTOMER_TESTING"
}

export interface NotificationChannelConfig {
    [NotificationChannel.SMS]: boolean,
    [NotificationChannel.WHATSAPP]: boolean,
    [NotificationChannel.ROBOCALL]: boolean
}

export interface Contact {
    name: string,
    contact_id: string,
    default: string,
    phone_number: string,
    notificationChannels: NotificationChannelConfig,
    status: ContactStatus
}

export interface AlertEvent {
    description: string,
    tmstamp: string,
    type: "INFO" | "WHATSAPP" | "SMS" | "ROBOCALL"
}

export interface Alert {
    device_id: string,
    user_id : string,
    seq_number: string,
    tmstamp: string,
    events: AlertEvent[],
}

export interface Device {
    name: string,
    last_seen: string,
    device_id: string,
    user_id: string,
    contacts: DeviceContact[],
    bat_status: string,
    bat_voltage: number,
    temperature: number,
    operation_mode: DeviceMode,
    registered: boolean,
    shipped: boolean
}