import { AmplifyAuthenticator } from "@aws-amplify/ui-react";
import React, { Component } from "react";
import { AuthState } from "@aws-amplify/ui-components";
import { CognitoUser } from "amazon-cognito-identity-js";

import { connect, ConnectedProps } from "react-redux";
import { SetLoggedInAction, SET_LOGGED_IN } from "../../redux/auth/types";
import { RootState } from "../../redux/store";
import { RouteComponentProps, RouteProps } from "react-router";

const mapState = (state: RootState) => ({})
  
const mapDispatch = {
    setIsLoggedIn: (isLoggedIn: Boolean, authUser: CognitoUser): SetLoggedInAction => ({type: SET_LOGGED_IN, isLoggedIn: isLoggedIn, authUser: authUser, authInitRun: true})
}

const connector = connect(mapState, mapDispatch);

type AuthComponentProps = ConnectedProps<typeof connector> & RouteComponentProps;

class AuthComponent extends Component<AuthComponentProps> {
    onStateChange(authState: AuthState, data?: object) {
        console.log(`onStateChange: [${authState.valueOf()}]`);
        if (authState === AuthState.SignedIn) {
            this.props.setIsLoggedIn(true, data as CognitoUser);
            console.log((data as CognitoUser).getSignInUserSession()?.getAccessToken().decodePayload()["cognito:groups"]);
            console.log("Props", this.props);
            if (this.props.location.search.length == 0) {
                window.location.reload(false);
            } else {
                this.props.history.push(this.props.location.search.replace("?redirect=", ""));
            }
        }
        console.log({...this.props});
        console.log("data odbject", authState, data as CognitoUser);
        
    }

    render() {
        return <div><AmplifyAuthenticator handleAuthStateChange={(authState: AuthState, data?: object) => this.onStateChange(authState, data)} /></div>;
    }
}

export default connector(AuthComponent);