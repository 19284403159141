import { combineReducers } from "redux";
import { authReducer } from "./auth/reducers";
import { AuthState } from "./auth/types";
import { cartReducer } from "./cart/reducers";
import { CartState } from "./cart/types";
import { contactsReducer } from "./contact/reducers";
import { ContactsState } from "./contact/types";
import { deviceReducer } from "./device/reducers";
import { DeviceState } from "./device/types";
import { orderReducer } from "./order/reducers";
import { OrderState } from "./order/types";

export const rootReducer = combineReducers({
    auth: authReducer,
    contacts: contactsReducer,
    devices: deviceReducer,
    cart: cartReducer,
    order: orderReducer
})
  
export type RootState = {
    auth : AuthState,
    contacts: ContactsState,
    devices: DeviceState,
    cart: CartState,
    order: OrderState
}