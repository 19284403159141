import React, { Component } from "react";
import { Contact, ContactStatus, NotificationChannel } from "components/client-area/common/models";
import { connect, ConnectedProps } from "react-redux";
import { CognitoUser } from "amazon-cognito-identity-js";
import { UpdateContactAction, UPDATE_CONTACT, DeleteContactAction, DELETE_CONTACT, ContactItem } from "redux/contact/types";
import { RootState } from "redux/store";
import withStyles, { Styles, WithStyles } from "@material-ui/core/styles/withStyles";
import { Grid, Paper, Chip, Tooltip, Collapse, Divider, Typography, CircularProgress, TextField, IconButton, Switch, Button, InputAdornment, InputBase, Box } from "@material-ui/core";
import EditableDisplayTextField from "components/common/components/EditableDisplayTextField/EditableDisplayTextField";
import { CheckCircleOutline, Delete, ExpandLessRounded, ExpandMoreRounded } from "@material-ui/icons";
import { API, Auth } from "aws-amplify";
import PhoneInput from "react-phone-input-2";
import { toast } from 'react-toastify';
import { DISABLED_NTF_CHANNELS } from "../constants";

const mapState = (state: RootState) => ({
    authUser: state.auth.user,
    contacts: state.contacts.contacts
});

const mapDispatch = {
    updateContact: (contact: Contact): UpdateContactAction => ({type: UPDATE_CONTACT, contact: contact}),
    deleteContact: (contactId: string): DeleteContactAction => ({type: DELETE_CONTACT, contact_id: contactId})
}

const connector = connect(mapState, mapDispatch);

const styles: Styles<any, any, string> = theme => ({
    paper: {
        padding: '1.3em'
    },
    verifiedContactChip: {
        backgroundColor: '#85ff85'
    },
    unverifiedContactChip: {
        backgroundColor: '#ff6161'
    },
    contactVerificationGrid: {
        alignSelf: 'center',
        textAlign: 'start'
    },
    innerDetailGrid: {
        padding: '0.5em'
    },
    defaultContactGrid: {
        paddingBottom: '1em'
    },
    gridDescCenter: {
        alignSelf: 'center'
    },
    notificationChannelUpdatingSpinner: {
        padding: '6px'
    },
    fields : {
        width: '100%'
    },
    gridAlignCenter: {
        alignSelf: 'center',
        textAlign: 'center'
    },
    gridAlignRight: {
        textAlign: 'end'
    },
    contactCardContactName: {
        alignSelf: 'center',
        textAlign: 'start',
        paddingLeft: '10px !important'
    },
    phoneControlInput: {
        padding: 10,
        width: '100% !important',
        height: '1.1876em'
    },
    phoneControlContainer : {
        width: '100% !important',
        marginTop: '2em'
    },
    editableDisplayTextFieldRoot: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    deleteParagraph: {
        textAlign: 'center'
    },
    deleteGrid: {
        paddingTop: '1em'
    }
});

enum ContactCardView {
    Details,
    Delete
}

enum DeleteContactState {
    Confirmation,
    Deleting,
    Deleted
  }

type ContactCardState = {
    infoExpanded: boolean, 
    updatingDefaultContact: boolean, 
    updatingNotificationChannel: NotificationChannel|null, 
    view: ContactCardView,
    deleteContactState: DeleteContactState
};
type ContactCardProps = {contact: ContactItem} & ConnectedProps<typeof connector> & WithStyles<any>;

class ContactCard extends Component<ContactCardProps, ContactCardState> {
    constructor(props: ContactCardProps) {
        super(props);
        this.state = {infoExpanded: false, updatingDefaultContact: false, updatingNotificationChannel: null, view: ContactCardView.Details, deleteContactState: DeleteContactState.Confirmation};
    }

    render() {
        let contact = this.props.contact.contact;
        const { classes } = this.props;
        return (
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={1}>
                        <Grid item xs={1} className={classes.contactVerificationGrid}>
                            {contact.status == ContactStatus.ACTIVE ?
                                <Chip label="Verified" size="small" className={classes.verifiedContactChip} /> :
                                <Chip label="Not Verified" size="small" className={classes.unverifiedContactChip} />}
                        </Grid>
                        <Grid item xs={3} className={classes.contactCardContactName}>
                            {contact.name}
                        </Grid>
                        <Grid item xs={8} className={classes.gridAlignRight}>
                            {!this.state.infoExpanded ?
                            <Tooltip title="More Information">
                                <IconButton onClick={() => this.setState({ infoExpanded: true })}>
                                    <ExpandMoreRounded/>
                                </IconButton>
                            </Tooltip>:
                            <IconButton onClick={() => this.setState({ infoExpanded: false })}>
                                    <ExpandLessRounded/>
                            </IconButton>}
                        </Grid>
                        {this.state.infoExpanded ?
                            <Grid item xs={12}>
                                <Collapse in={this.state.infoExpanded}>
                                    <Divider />
                                    <Grid className={classes.innerDetailGrid} container spacing={1}>
                                        {this.state.view == ContactCardView.Details ?
                                            <Grid container xs={11}>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                        Contact Information
                                                    </Typography>
                                                    <Grid className={classes.innerDetailGrid} container spacing={1} style={{paddingLeft: '1em'}}>
                                                        <Grid container className={classes.defaultContactGrid}>
                                                            <Grid item xs={6} className={classes.gridDescCenter}>
                                                                Default Contact
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                {this.state.updatingDefaultContact ?
                                                                    <CircularProgress size="1.5rem" className={classes.notificationChannelUpdatingSpinner} />:
                                                                    <Switch 
                                                                        color="primary"
                                                                        checked={contact.default == "true"}
                                                                        onChange={(evt: any, checked: boolean) => this.toggleDefault(checked)} />
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <EditableDisplayTextField
                                                            label="Contact Name"
                                                            value={contact.name}
                                                            updateFunc={(val) => 
                                                                this.updateName({...contact}, val)}
                                                            customClassName={classes.fields}
                                                            customRootClassName={classes.editableDisplayTextFieldRoot} />
                                                        <PhoneInput 
                                                            country='za'
                                                            inputProps={{
                                                                endAdornment: <InputAdornment position="end"><div>bleh</div></InputAdornment>
                                                            }}
                                                            containerClass={classes.phoneControlContainer}
                                                            inputClass={classes.phoneControlInput}
                                                            masks={{za: '(..) ... ....'}}
                                                            value={contact.phone_number}
                                                            disabled={true}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2} />
                                                <Grid item xs={3}>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                        Notification Channels
                                                    </Typography>
                                                    {Object.keys(NotificationChannel).map(cnl => {
                                                        let updatingChannel = this.state.updatingNotificationChannel;
                                                        return (
                                                        <Grid container xs={12} style={{paddingLeft: '1em'}}>
                                                            <Grid item xs={6} className={classes.gridDescCenter}>
                                                                {cnl}
                                                            </Grid>
                                                            <Grid item xs={5} className={classes.gridAlignCenter}>
                                                                {updatingChannel && updatingChannel == cnl ?
                                                                    <CircularProgress size="1.5rem" className={classes.notificationChannelUpdatingSpinner} /> :
                                                                    DISABLED_NTF_CHANNELS.includes(cnl as NotificationChannel) ?
                                                                        <Tooltip title="Currently unavailable - Coming soon">
                                                                            <span>
                                                                                <Switch color="primary" disabled={true} />
                                                                            </span>
                                                                        </Tooltip> :
                                                                        <Switch 
                                                                            color="primary" 
                                                                            checked={contact.notificationChannels[cnl as NotificationChannel]}
                                                                            onChange={(evt: any, checked: boolean) => this.toggleNotificationChannel({...contact, notificationChannels: {...contact.notificationChannels}}, cnl as NotificationChannel, evt)}
                                                                            disabled={updatingChannel !== null} />
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    )})}
                                                </Grid>
                                                <Grid item xs={2}></Grid>
                                            </Grid> :
                                            <Grid container xs={11}>
                                                <Grid item xs={4}></Grid>
                                                <Grid item xs={4} className={classes.deleteGrid}>
                                                    <div className={classes.deleteParagraph}>
                                                        Deleting this contact means that they will no longer receive alerts.
                                                    </div>
                                                    <br/><br/>
                                                    <div className={classes.deleteParagraph}>
                                                        {(() => {
                                                            switch(this.state.deleteContactState) {
                                                                case DeleteContactState.Confirmation:
                                                                    return "Are you sure?"
                                                                case DeleteContactState.Deleting:
                                                                    return "Deleting..."
                                                                case DeleteContactState.Deleted:
                                                                    return "Deleted"
                                                            }
                                                        })()}
                                                    </div>
                                                    <br/><br/>
                                                    <Grid container
                                                        direction="row"
                                                        justify="space-evenly"
                                                        alignItems="center">
                                                            {(() => {
                                                                switch (this.state.deleteContactState) {
                                                                    case DeleteContactState.Confirmation:
                                                                        return (
                                                                            <Grid container xs={12} direction="row" justify="space-evenly" alignItems="center">
                                                                                <Button color="primary" variant="contained"
                                                                                    onClick={this.deleteContact.bind(this)}>Yes</Button>
                                                                                <Button color="primary" variant="contained"
                                                                                    onClick={() => this.setState({view: ContactCardView.Details})}>No</Button>
                                                                            </Grid>
                                                                        );
                                                                    case DeleteContactState.Deleting:
                                                                        return (
                                                                            <Grid item xs={12} className={classes.gridAlignCenter}>
                                                                                <CircularProgress size="2rem" />
                                                                            </Grid>
                                                                        );
                                                                    case DeleteContactState.Deleted:
                                                                        return (
                                                                            <Grid item xs={12} className={classes.gridAlignCenter}>
                                                                                <CheckCircleOutline style={{ fontSize: '2rem', color: 'green' }}/>
                                                                            </Grid>
                                                                        );
                                                                }})()}
                                                            
                                                        </Grid>
                                                </Grid>
                                                <Grid item xs={4}></Grid>
                                            </Grid>
                                        }
                                        {this.state.view == ContactCardView.Details ?
                                        <Grid item xs={1}>
                                            <IconButton aria-label="delete" onClick={() => this.setState({view: ContactCardView.Delete})}>
                                                <Delete style={{color: 'red', fontSize: '2rem' }} />
                                            </IconButton>
                                        </Grid>
                                        : null}
                                    </Grid>
                                </Collapse>
                            </Grid> : null}
                    </Grid>
                </Paper>
            </Grid>  
        );
    }

    toggleDefault(checked: boolean) {
        this.setState({updatingDefaultContact: true});
        let contact = {...this.props.contact.contact};
        contact.default = checked.toString();
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            API.put('IceApi', '/api/contact', {
                body: {contact: contact}, 
                headers: {Authorization: sessionToken}})
            .then(data => {
                console.log("success!", data);
                this.setState({updatingDefaultContact: false});
                this.props.updateContact(data.message);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.log("Error getting authenticated user", err)); 
    }

    updateName(contact: Contact, name: string) {
        contact.name = name;
        return Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            return API.put('IceApi', '/api/contact', {
                body: {contact: contact}, 
                headers: {Authorization: sessionToken}})
            .then(data => {
                console.log("success!", data);
                this.props.updateContact(data.message);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.log("Error getting authenticated user", err)); 
    }

    toggleNotificationChannel(contact: Contact, notificationChannel: NotificationChannel, evt: React.ChangeEvent<HTMLInputElement>) {
        contact.notificationChannels[notificationChannel] = evt.target.checked;
        this.setState({updatingNotificationChannel: notificationChannel});
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            API.put('IceApi', '/api/contact', {
                body: {contact: contact}, 
                headers: {Authorization: sessionToken}})
            .then(data => {
                console.log("success!", data);
                this.props.updateContact(data.message);
                this.setState({updatingNotificationChannel: null});
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.log("Error getting authenticated user", err)); 
    }

    deleteContact() {
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            API.del('IceApi', '/api/contact', {
                body: {contact_id: this.props.contact.contact.contact_id}, 
                headers: {Authorization: sessionToken}})
            .then(data => {
                console.log("success!", data);
                toast.success("Contact successfully deleted", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                this.setState({deleteContactState: DeleteContactState.Deleted});
                setTimeout(() => this.props.deleteContact(data.message), 2000);
            })
            .catch(err => console.error(err));
            this.setState({deleteContactState: DeleteContactState.Deleting});
        })
        .catch(err => console.log("Error getting authenticated user", err)); 
    }
}

export default connector(withStyles(styles)(ContactCard));