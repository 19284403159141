export type Address = {
    address_id : string,
    recipient_name: string,
    recipient_cell_number: string,
    street_name?: string, 
    complex_building?: string,
    suburb: string,
    city_town: string,
    province: string,
    postal_code: string
};

export enum OrderStatus {
    PAID = "PAID",
    AWAITING_SHIPMENT_SUPPORT = "AWAITING_SHIPMENT_SUPPORT",
    AWAITING_PAYMENT = "AWAITING_PAYMENT",
    SHIPPED = "SHIPPED",
    INIT = "INIT",
};

export type OrderAttachedItem = {
    product_id: string,
    attached_device_id: string|undefined
};

export type OrderItem = {
    order: Order
};

export type ShippingOffering = {
    retrieved: boolean,
    shipping_cost: number,
    estimated_delivery_date: string,
    estimated_shipping_days: number
}

export type Order = {
    order_id: string|null,
    address_id: string | undefined,
    status: OrderStatus,
    items: OrderAttachedItem[],
    total: number,
    shipping_offering: ShippingOffering|null // make this non-null
};

export interface CheckoutOrderProduct{
    product_id : string,
    name : string,
    price_per_month : number,
    device_cost: number
};

export type CheckoutOrderItem = {
    order: CheckoutOrder
};

export type CheckoutOrder = {
    order_id: string|null,
    address: Address|null,
    status: OrderStatus,
    items: CheckoutOrderProduct[],
    total: number,
    shipping_offering: ShippingOffering|null // make this non-null
};

export interface OrderState{
    address : Address | undefined,
    checkout_order: CheckoutOrderItem|null,
    orders: OrderItem[],
    ordersInitialised: boolean
};

///// Actions
export const SET_ADDRESS = "SET_ADDRESS";

export interface SetAddress {
    type: typeof SET_ADDRESS,
    address: Address
};

export const SET_ORDERS = "SET_ORDERS";

export interface SetOrdersAction {
    type: typeof SET_ORDERS,
    orders: OrderItem[]
};

export const UPDATE_ORDER = "UPDATE_ORDER";

export interface UpdateOrderAction {
    type: typeof UPDATE_ORDER,
    order: Order
};

export const SET_CHECKOUT_ORDER = "SET_CHECKOUT_ORDER";

export interface SetCheckoutOrderAction {
    type: typeof SET_CHECKOUT_ORDER,
    order: CheckoutOrder|null
};

export const UPDATE_CHECKOUT_ORDER_ADDRESS = "UPDATE_CHECKOUT_ORDER_ADDRESS";

export interface UpdateCheckoutOrderAddressAction {
    type: typeof UPDATE_CHECKOUT_ORDER_ADDRESS,
    address: Address
};

export const UPDATE_CHECKOUT_ORDER_SHIPPING_OFFER = "UPDATE_CHECKOUT_ORDER_SHIPPING_OFFER";

export interface UpdateCheckoutOrderShippingOfferAction {
    type: typeof UPDATE_CHECKOUT_ORDER_SHIPPING_OFFER,
    shipping_offering: ShippingOffering
};

export const UPDATE_CHECKOUT_ORDER_ID = "UPDATE_CHECKOUT_ORDER_ID";

export interface UpdateCheckoutOrderIDAction {
    type: typeof UPDATE_CHECKOUT_ORDER_ID,
    order_id: string
};

export type OrderActions = SetAddress | SetOrdersAction | UpdateOrderAction 
    | SetCheckoutOrderAction | UpdateCheckoutOrderAddressAction | UpdateCheckoutOrderShippingOfferAction
    | UpdateCheckoutOrderIDAction;