import { RootState } from "../../redux/store";
import { CartProduct, AddProductToCart, RemoveProductFromCart, ADD_TO_CART, REMOVE_FROM_CART, EmptyCartAction, EMPTY_CART } from "../../redux/cart/types";
import { connect, ConnectedProps } from "react-redux";
import React, { Component } from "react";

import HeaderLinks from "components/Header/HeaderLinks";
import Header from "components/Header/Header";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { CircularProgress, Divider, Grid, Hidden, Link, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { withStyles, WithStyles } from '@material-ui/styles';
import { Styles } from "@material-ui/core/styles/withStyles";
import AddressComponent from "./addresses/AddressComponent";
import PaymentComponent from "./payment/PaymentComponent";
import OrderConfirmation from "./order-confirmation";
import { RouteComponentProps } from "react-router";
import { API, Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { CheckoutOrder, CheckoutOrderProduct, Order, OrderAttachedItem, OrderStatus, SetCheckoutOrderAction, SET_CHECKOUT_ORDER } from "redux/order/types";
import { CheckoutPageView } from "./types";



const mapState = (state: RootState) => ({
  cart: state.cart,
  checkout_order: state.order.checkout_order
});

const mapDispatch = {
  setCheckoutOrder: (order: CheckoutOrder|null): SetCheckoutOrderAction => ({type: SET_CHECKOUT_ORDER, order: order}),
  emptyCart: (): EmptyCartAction => ({type: EMPTY_CART})
}

type CheckoutPageState = {
  activeStep: CheckoutPageView,
  paymentRef: React.RefObject<any>,
  retrievingOrder: boolean,
  nextStepButtonInProgress: boolean
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const styles: Styles<any, any, string> = theme => ({
  paper: {
    padding: '1.3em'
  },
  root: {
    // width: '100%',
  },
  button: {
    // marginRight: theme.spacing(1),
  },
  instructions: {
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
});

function getSteps() {
  return ['Delivery', 'Payment', 'Confirmation'];
}

type CheckoutPageProps = PropsFromRedux & WithStyles<any> & RouteComponentProps;

class CheckoutPage extends Component<CheckoutPageProps, CheckoutPageState>{
  constructor(props: CheckoutPageProps) {
    super(props);
    this.state = {
      activeStep: CheckoutPageView.LOADING,
      paymentRef: React.createRef(),
      retrievingOrder: false,
      nextStepButtonInProgress: false
    };
  }

  componentDidMount() {
    console.log("CheckoutPage componentDidMount", this.props);
    if (this.props.match.params["orderid"] !== undefined) {
      console.log("Let's load up the order!");
      Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => {
          let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
          Promise.allSettled([
            API.get('IceApi', '/api/order', {
              queryStringParameters: {
                orderId: this.props.match.params["orderid"],
                return_checkout_order: true
              },
              headers: {Authorization: sessionToken}})
            .then((data: CheckoutOrder[]) => {
                console.log("retrieve order success!", data);
                this.props.setCheckoutOrder(data[0]);
                return data[0].status == OrderStatus.AWAITING_PAYMENT;
            })
            .catch(err => {
              console.error(err);
              return false;
            }),
            new Promise<boolean>(resolve => setTimeout(() => resolve(true), 3000))
          ])
          .then((data: PromiseSettledResult<boolean>[]) => {
            console.log("ouyfuoyfuyf", data);
            if (data[0].status == "fulfilled") {
              this.setState({retrievingOrder: false, activeStep: data[0].value ? CheckoutPageView.PAYMENT : CheckoutPageView.DELIVERY_ADDRESS});
            }
          });
          this.props.setCheckoutOrder(null);
      })
      .catch(err => console.log("Error getting authenticated user", err));
      this.setState({retrievingOrder: true});
    } else {
      console.log("Creating initial order");
      this.props.setCheckoutOrder({
        order_id: null,
        address: null,
        status: OrderStatus.INIT,
        items: this.getItems(),
        total: 0,
        shipping_offering: null
      });
      this.setState({activeStep: CheckoutPageView.DELIVERY_ADDRESS});
    }
  }

  getItems(): CheckoutOrderProduct[] {
    console.log("Cart Products", this.props.cart.products);
    let items: CheckoutOrderProduct[] = [];
    for (let product_id in this.props.cart.products) {
      console.log("Cart Product", product_id);
      let item: CartProduct = this.props.cart.products[product_id];
      
      items = items.concat(
        Array.from({length: this.props.cart.product_count[product_id]}, () => ({
          product_id: product_id, 
          name: item.name, 
          price_per_month: item.price_per_month, 
          device_cost: item.device_cost}))
      );
      console.log(items);
    }
    return items;
  }

  calculateTotalMonthlyCost(): number {
    let total: number = 0.0;
    for (let product_id in this.props.cart.products) {
      total += this.props.cart.products[product_id].price_per_month * this.props.cart.product_count[product_id];
    }
    return total;
  }

  calculateTotalDeviceCost(): number {
    // let total: number = 0.0;
    let total = this.props.checkout_order?.order.items.reduce<number>((prev: number, cur) => prev + cur.device_cost, 0) || 0;
    // for (let product_id in this.props.cart.products) {
    //   total += this.props.checkout_order?.order.items.products[product_id].device_cost * this.props.cart.product_count[product_id];
    // }
    return total;
  }

  calculateTotalAmount(): number {
    return (this.props.checkout_order?.order.items.reduce<number>((prev: number, cur) => prev + cur.device_cost, 0) || 0) + 
      (this.props.checkout_order?.order.shipping_offering?.shipping_cost || 0);
  }

  getStepContent() {
    switch (this.state.activeStep) {
      case CheckoutPageView.DELIVERY_ADDRESS:
        return <AddressComponent setStep={(step: number) => { this.setState({activeStep: step }) }} />;
      case CheckoutPageView.PAYMENT:
        return <PaymentComponent paymentRef={this.state.paymentRef} />;
      case CheckoutPageView.CONFIRMATION:
        return <OrderConfirmation/>;
      default:
        return 'Unknown step';
    }
  }

  handleNextStep() {
    switch (this.state.activeStep) {
      case CheckoutPageView.DELIVERY_ADDRESS:
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            let order: Order = {
              order_id: null,
              address_id: this.props.checkout_order!.order.address?.address_id,
              status: OrderStatus.AWAITING_PAYMENT,
              items: this.props.checkout_order!.order.items.map<OrderAttachedItem>(i => ({product_id: i.product_id, attached_device_id: undefined})),
              total: this.calculateTotalAmount(),
              shipping_offering: this.props.checkout_order!.order.shipping_offering
            };
            API.post('IceApi', '/api/order', {
                body: {return_checkout_order: true, order: order},
                headers: { Authorization: sessionToken }
            })
                .then((data: CheckoutOrder) => {
                    console.log("create order address success!", data);
                    this.props.setCheckoutOrder(data);
                    this.props.emptyCart();
                    this.setState({activeStep: CheckoutPageView.PAYMENT, nextStepButtonInProgress: false});
                })
                .catch(err => {
                    console.error(err);
                });
        });
        this.setState({nextStepButtonInProgress: true});
        break;
      case CheckoutPageView.PAYMENT:
        console.log("handleNextStep CheckoutPageView.PAYMENT");
        break;
      case CheckoutPageView.CONFIRMATION:
        console.log("handleNextStep CheckoutPageView.CONFIRMATION");
        break;
      default:
        console.log("handleNextStep unknown");
    }
  }

  render() {
    const steps = getSteps();
    const { classes } = this.props;

    return (
      <div>
        <Header
          color="white"
          brand="Sateria"
          rightLinks={<HeaderLinks />}
        />
        <Grid container spacing={3}>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={7} md={7}>
            {this.state.retrievingOrder ?
              <div>
                Loading Order
                <CircularProgress size="2rem" />
              </div> :
              this.state.activeStep == CheckoutPageView.LOADING ?
              <div>Creating Order</div> :
              <Paper className={classes.paper}>
                <Stepper activeStep={this.state.activeStep.valueOf()}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <div>
                  <div>
                    {this.getStepContent()}
                  </div>
                </div>
              </Paper>
              }
          </Grid>
          <Grid item xs={3} md={3} >
            <Paper className={classes.paper}>
              <Box fontWeight="fontWeightBold" >
                Order Summary
              </Box>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {this.props.checkout_order?.order.items.length || 0} Items
              </Typography>
              <Typography className={classes.title} color="textPrimary" gutterBottom>
                R {this.calculateTotalDeviceCost()}
              </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Delivery 
              </Typography>
              <Typography className={classes.title} color="textPrimary" gutterBottom>
                {this.props.checkout_order?.order.shipping_offering?.shipping_cost ?
                `R ${this.props.checkout_order?.order.shipping_offering?.shipping_cost}`:
                '-'}
              </Typography>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                Total
                        </Typography>
              <Typography component="div">
                <Box fontWeight="fontWeightBold">
                  {this.props.checkout_order?.order.shipping_offering?.shipping_cost ?
                  `R ${this.calculateTotalAmount()}`:
                  '-'}
                  {/* {this.state.activeStep === 1 ? <div>R{this.calculateTotalDeviceCost() + 150}</div> : <div>R{this.calculateTotalMonthlyCost()}</div>} */}
                </Box>
              </Typography>
              <Divider></Divider>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={this.state.retrievingOrder
                  || this.state.nextStepButtonInProgress
                  || this.props.checkout_order === null 
                  || this.props.checkout_order.order.address === undefined
                  || (this.props.checkout_order.order?.shipping_offering?.retrieved || false) == false}
                onClick={this.handleNextStep.bind(this)}
                className={classes.button}
              >
                {this.state.nextStepButtonInProgress ?
                <CircularProgress size="2rem" />:
                this.state.activeStep === CheckoutPageView.DELIVERY_ADDRESS ? 'Continue' : 'Pay'
              }
              </Button>

            </Paper>
          </Grid>
        </Grid>

      </div>
    );
  }
}

export default connector(withStyles(styles)(CheckoutPage));