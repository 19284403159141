import { Box, TextField, IconButton, Grid, Typography } from "@material-ui/core";
import { TouchApp, AddShoppingCart, Payment, PermDeviceInformation, AttachMoney, Share, Facebook, Instagram, Email, WhatsApp } from "@material-ui/icons";
import withStyles, { Styles, WithStyles } from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import React, { Component } from "react";
import ProductInfo from "./ProductInfo";
import { connect, ConnectedProps } from "react-redux";
import { AddProductToCart, ADD_TO_CART, CartProduct } from "redux/cart/types";

const styles: Styles<any, any, string> = theme => ({
    imgRaised: {
        boxShadow: "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    imgRoundedCircle: {
        borderRadius: "50% !important"
    },
    imgFluid: {
        maxWidth: "100%",
        height: "auto"
    },
    productTitle: {
        alignSelf: 'center',
        textAlign: 'center'
    }
  });

const connector = connect(null, {
    addToCart: (product: CartProduct): AddProductToCart => ({type: ADD_TO_CART, product: product})
});

type ProductComponentProps = {productInfo: ProductInfo} & WithStyles<any> & ConnectedProps<typeof connector>;
class ProductComponent extends Component<ProductComponentProps, {}> {
    constructor(props: ProductComponentProps) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        const imageClasses = classNames(
            classes.imgRaised,
            classes.imgRoundedCircle,
            classes.imgFluid
        );

        if (this.props.productInfo === null) {
            return (
                <div>
                    Product does not exist
                </div>
            );
        }

        return (
            <div>
                <div className={classes.container}>
                    <Grid container alignItems="center" justify="center">
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid container xs={12}>
                                <Grid item xs={3}>
                                    <img src={`https://sateria.io/${this.props.productInfo.getImageURL()}`} alt="..." className={imageClasses} />
                                </Grid>
                                <Grid item xs={9} className={classes.productTitle}>
                                    <Typography variant="h2" gutterBottom>
                                        {this.props.productInfo.getTitle()}
                                    </Typography>
                                    <div>
                                        {this.props.productInfo.getDescription()}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} style={{backgroundColor: '#f5f5f5', borderRadius: '10px', padding: '0.5em', marginTop: '1em'}}>
                                <Grid item xs={4}>
                                        <Grid container xs={12} direction="row">
                                            <AttachMoney />
                                            <Typography variant="h6" gutterBottom>
                                                Price
                                            </Typography>
                                        </Grid>
                                        <Grid container xs={12} style={{paddingLeft: '1.5em'}}>
                                            <Typography variant="subtitle1" style={{fontSize: '2em'}} gutterBottom>
                                                R{this.props.productInfo.getMonthlyPrice()} p/m
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                * See more information on pricing below
                                            </Typography>
                                        </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                        <Grid container xs={12} direction="row">
                                            <TouchApp />
                                            <Typography variant="h6" gutterBottom>
                                                Use Cases
                                            </Typography>
                                        </Grid>
                                        <Grid container xs={12} style={{paddingRight: '1em'}}>
                                            {this.props.productInfo.getUseCases()}
                                        </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                        <Grid container xs={12} direction="row">
                                            <PermDeviceInformation />
                                            <Typography variant="h6" gutterBottom>
                                                Device Info
                                            </Typography>
                                        </Grid>
                                        <Grid container xs={12} style={{paddingLeft: '1.5em'}}>
                                            {this.props.productInfo.getDeviceInfo()}
                                        </Grid>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} style={{backgroundColor: '#f5f5f5', borderRadius: '10px', padding: '0.5em', marginTop: '1em'}}>
                                <div>
                                    <Grid container xs={12} direction="row">
                                        <Payment />
                                        <Typography variant="h6" gutterBottom>
                                            Pricing
                                        </Typography>
                                    </Grid>
                                    <Typography variant="body1" gutterBottom>
                                        The first month's payment will be paid with the order and the recurring monthly cost will only begin once the device is activated
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        Thereafter, subscription payments will be deducted on the 25th of every month
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        More cool information we wish to tell the customer about how rich they are going to make us goes here.
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        We can also include some tool here if we wish to offer discounts depending on how they pay/how many devices are bought
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid container xs={12} style={{backgroundColor: '#f5f5f5', borderRadius: '10px', padding: '0.5em', marginTop: '1em'}}>
                                <Grid item xs={12} direction="row">
                                    <Grid container xs={12} direction="row">
                                        <Share />
                                        <Typography variant="h6" gutterBottom>
                                            Share
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} direction="row">
                                    <Facebook style={{fontSize: '3em'}} />
                                    <Instagram style={{fontSize: '3em'}} />
                                    <WhatsApp style={{fontSize: '3em'}} />
                                    <Email style={{fontSize: '3em'}} />
                                </Grid>
                            </Grid>
                            <br/>
                            <Box textAlign='center'>
                                <TextField 
                                type="number"
                                defaultValue="1"
                                InputProps={{
                                    inputProps: { 
                                        max: 100, min: 1 
                                    }
                                }}
                                />
                                <IconButton color="primary" aria-label="add to shopping cart" onClick={() => {
                                    this.props.addToCart(
                                        {
                                            product_id: this.props.productInfo.getProductID(),
                                            img_uri: this.props.productInfo.getImageURL(),
                                            name: this.props.productInfo.getTitle(),
                                            price_per_month: this.props.productInfo.getMonthlyPrice(),
                                            device_cost: this.props.productInfo.getDeviceCost()
                                        });
                                }}>
                                    <AddShoppingCart /> 
                                    Add to Cart
                                </IconButton>
                            </Box>
                        </Grid>
                        <br/>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default connector(withStyles(styles)(ProductComponent));