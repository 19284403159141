import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
// @material-ui/icons

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardFooter from "../../../components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import panicbuttonimg from "../../../assets/img/products/panic-button.jpeg";
import panicbuttonadvancedimg from "../../../assets/img/products/panic-button-advanced.png";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>0G Panic Butons</h2>
      <div>
        <GridContainer
          alignItems="center"
          justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <Link to="/product/OgSPFixed" className={classes.dropdownLink}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={panicbuttonimg} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                0G Panic Button Fixed
              </h4>
              </Link>
              <CardBody>
                <p className={classes.description}>
                0G Panic Button Fixed description.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={4}>
            <Card plain>
            <Link to="/product/OgSPAdvanced" className={classes.dropdownLink}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={panicbuttonadvancedimg} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                0G Panic Button Advanced
              </h4>
              </Link>
              <CardBody>
                <p className={classes.description}>
                0G Panic Button Advanced description.
                </p>
              </CardBody>
            </Card>
          </GridItem> */}
        </GridContainer>
      </div>
    </div>
  );
}
