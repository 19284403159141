import { ContactSupportOutlined } from "@material-ui/icons";
import { ADD_TO_CART, CartActions, CartState, CartProduct, REMOVE_FROM_CART, EMPTY_CART } from "./types";

let cartFromStorage = localStorage.getItem('cart')

const initialState: CartState = cartFromStorage != null ? JSON.parse(cartFromStorage || '{}') : {
    products : {},
    product_count : {},
    count : 0
}

export function cartReducer(state = initialState, action: CartActions): CartState {
    switch (action.type) {
        case ADD_TO_CART:
            if (action.product.product_id in state.products){
                state.product_count[action.product.product_id] = state.product_count[action.product.product_id] + 1
            } else {
                state.products[action.product.product_id] = action.product
                state.product_count[action.product.product_id] = 1
            }
            
            state.count = 0
            for(let id in state.product_count){
                state.count = state.count + state.product_count[id]
            }
            localStorage.setItem('cart', JSON.stringify(state));

            return {...state, products: state.products, product_count: state.product_count, count: state.count};
        case REMOVE_FROM_CART:            
            if(state.product_count[action.product.product_id] -1 <= 0){
                delete state.product_count[action.product.product_id]
                delete state.products[action.product.product_id]
                console.log(state.products)
            } else {
                state.product_count[action.product.product_id] = state.product_count[action.product.product_id] -1
            }

            state.count = 0
            for(let id in state.product_count){
                state.count = state.count + state.product_count[id]
            }

            localStorage.setItem('cart', JSON.stringify(state));
            return {...state, products: state.products, product_count: state.product_count, count: state.count};
        case EMPTY_CART:
            console.log("EMPTY_CART", state);
            state.product_count = {};
            state.count = 0;
            state.products = {};
            localStorage.setItem('cart', JSON.stringify(state));
            console.log("EMPTY_CART after", state);
            return state;
        default: return state;
    }
}