import React, { Component } from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import AuthComponent from "components/auth/auth";
import ProtectedRouteCustomers from "components/navigation/protected-route-customers";
import ProtectedRouteAdministrators from "components/navigation/protected-route-admin";
import ProtectedRouteUnauthorized from "components/navigation/protected-route-unauthorized";

import TestComponent from "components/client-area/test/test";
import AdminComponent from "components/admin-area/admin";
import ContactsPage from "components/client-area/contacts/contacts";
import CartPage from "components/cart/cart";
import CheckoutPage from "components/checkout/checkout";
import DevicesPage from "components/client-area/devices/devices";

import LandingPage from "views/LandingPage/LandingPage.js";
import ProductPage from "views/ProductPage/ProductPage.js"

import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './redux/store';
import { SetLoggedInAction, SET_LOGGED_IN } from './redux/auth/types';
import { Auth } from 'aws-amplify';
import { CognitoUser } from "amazon-cognito-identity-js";
import { ToastContainer } from 'react-toastify';

const Routes = (props: {isLoggedIn: Boolean}) => (
  <Switch>
    <Route exact path="/cart" component={CartPage} />
    <Route exact path="/" component={LandingPage} />
    <Route exact path="/auth" render={
      () => {
        return (
            props.isLoggedIn ?
            <Redirect to="/" /> :
            <Route exact path="/auth" component={AuthComponent}/>
        )
    }}/>
    <ProtectedRouteAdministrators
      path="/admin"
      component={AdminComponent}
    />
    <ProtectedRouteUnauthorized
      exact
      path="/product/:id"
      component={ProductPage}
    />
    <ProtectedRouteCustomers
      exact
      path="/clientArea"
      component={TestComponent}
    />
    <ProtectedRouteCustomers
      exact
      path="/clientArea/contacts"
      component={ContactsPage}
    />
    <ProtectedRouteCustomers
      exact
      path="/clientArea/devices"
      component={DevicesPage}
    />
    <ProtectedRouteCustomers
      exact
      path="/checkout/:orderid?"
      component={CheckoutPage}
    />
    <Route exact path="/about" render={() => <div>About Content</div>}/>
  </Switch>
);

const mapState = (state: RootState) => ({
  isLoggedIn: state.auth.isLoggedIn,
  authUser: state.auth.user
})

const mapDispatch = {
  setIsLoggedIn: (isLoggedIn: Boolean, authUser?: CognitoUser): SetLoggedInAction => ({type: SET_LOGGED_IN, isLoggedIn: isLoggedIn, authUser: authUser, authInitRun: true})
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

class App extends Component<PropsFromRedux, {device_id: string}> {
  constructor(props: any) {
    super(props);
    this.state = {device_id: ""}
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then((data: CognitoUser) => {
      console.log('App login check', data);
      this.props.setIsLoggedIn(true, data);
    })
    .catch(err => console.error('App login check error', err));
  }

  onInput(evt: any) {
    console.log(evt);
    this.setState({device_id: evt.target.value});
  }
  
  render() {
    console.log(this.props.isLoggedIn)
    return (

      <div>
        <Router>
          <Routes isLoggedIn={this.props.isLoggedIn}/>
        </Router>
        <ToastContainer/>
      </div>
    );
  }

}

export default connector(App);
