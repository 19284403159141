import React, { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "../../../redux/store";

import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { SetLoggedInAction, SET_LOGGED_IN } from "../../../redux/auth/types";

import ContactsPage from "../contacts/contacts";
import DevicesPage from "../devices/devices";
import { Link } from "react-router-dom";

const mapState = (state: RootState) => ({
  authUser: state.auth.user
})
  
const mapDispatch = {
  setIsLoggedIn: (user: CognitoUser): SetLoggedInAction => ({type: SET_LOGGED_IN, isLoggedIn: true, authUser: user})
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

class TestComponent extends Component<PropsFromRedux, {name: string}> {
    constructor(props: any) {
        super(props);
        this.state = {name: (this.props.authUser as any).attributes?.name || ""};
    }
      
    async updateName() {
      let user = ((await Auth.currentAuthenticatedUser()) as CognitoUser);
      Auth.updateUserAttributes(user, {"name": this.state.name})
      .then(async data => {
        console.log(`updateUserAttributes ${data}`);
        this.props.setIsLoggedIn(await Auth.currentUserPoolUser());
        alert(`Success! Check Cognito attributes for '${user.getUsername()}'`);
      })
      .catch(err => {
        console.error("Failed!", err);
        alert("Error! Check Console");
      });
    }
      
    render() {
        return (
            <div>
                <Link to="/clientArea/contacts">Take me to Contacts!</Link>
                <br />
                <Link to="/clientArea/devices">Take me to Devices!</Link>
                <h2>This is a test zone for Customer functionality.</h2>
                {/* Update name attribute */}
                <h4>Update Name Attribute</h4>
                <p>Enter a name for yourself, then click 'Update Name', and wait for an alert</p>
                <input value={this.state?.name ?? undefined} onChange={(evt) => this.setState({name: evt.target.value})} />
                <button onClick={async () => await this.updateName()}>Update Name</button>
            </div>
        );
    }
}

export default connector(TestComponent);