import { Button, CircularProgress, Grid, Paper, TextField } from "@material-ui/core";
import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DeviceItem, SetDevicesAdminAction, SET_DEVICES_ADMIN } from "redux/device/types";
import { RootState } from "redux/store";
import { withStyles, WithStyles } from '@material-ui/styles';
import { Styles } from "@material-ui/core/styles/withStyles";
import { Auth, API } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Device, DeviceMode } from "components/client-area/common/models";
import { Autocomplete } from "@material-ui/lab";
import { toast } from 'react-toastify';

const mapState = (state: RootState) => ({
    devices: state.devices.devicesAdmin,
    devicesInitialised: state.devices.devicesAdminInitialised
});
const mapDispatch = {
    setDevices: (devices: Device[]): SetDevicesAdminAction => ({ type: SET_DEVICES_ADMIN, devices: devices })
};

const connector = connect(mapState, mapDispatch);

const styles: Styles<any, any, string> = theme => ({
    paper: {
        padding: '1.3em'
    },
    spinner: {
        position: 'fixed',
        top: '50%',
        left: '50%'
    }
});

interface UserDTO {
    username: string,
    sub: string
}

type AdminDevicesProps = ConnectedProps<typeof connector> & WithStyles<any>;
type AdminDevicesState = {device_id: string, users: UserDTO[], selectedSub: string};

class AdminDevices extends Component<AdminDevicesProps, AdminDevicesState> {

    constructor(props: AdminDevicesProps) {
        super(props);
        console.log(props);
        this.state = {device_id: "", users: [], selectedSub: ""};
    }

    retrieveDevices(override: boolean = false) {
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
          if (!this.props.devicesInitialised || override) {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            API.get("IceApi", "/api/device", {
              headers: {Authorization: sessionToken},
              queryStringParameters: {
                all_items: true,
            }
            })
            .then((data: Device[]) => {
              this.props.setDevices(data);
              console.log("Success Devices!", data);
            })
            .catch(err => console.error("Error!", err)); 
            }
        })
        .catch(err => console.log("Error getting authenticated user", err)); 
    }

    retrieveUsers() {
        console.log("Calling API for retrieveUsers");
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
          let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
          API.get("IceApi", "/api/user", {
            headers: {Authorization: sessionToken}
          })
          .then((data: any[]) => {
            this.setState({users: data.map<UserDTO>(user => { return {username: user.Username, sub: user.Attributes.sub}; })});
            console.log("Success!", data);
          })
          .catch(err => console.error("Error!", err));
        })
        .catch(err => console.log("Error getting authenticated user", err));      
    }

    async registerDevice() {
        console.log("Calling API for registerDevice");
        let user = ((await Auth.currentAuthenticatedUser()) as CognitoUser);
        let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
        API.post("IceApi", "/api/device", {
          body: {device_id: this.state.device_id},
          headers: {Authorization: sessionToken}})
        .then(data => {
          console.log("Success!", data);
          this.setState({device_id: ""});
          toast.success(`Device [${this.state.device_id}] added`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });  
        })
        .catch(err => {
          console.error("Failed!", err);
          toast.error("An error ocurred attempting to register a new device", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });  
        });
      }

    componentDidMount() {
        this.retrieveDevices();
        this.retrieveUsers();
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Grid>
                    <Grid container>
                      <Grid item xs={12}><h2>Add a Device</h2></Grid>
                      <Grid xs={12}>
                        <Grid item xs={12}>
                          <TextField 
                            className={classes.fields}
                            onChange={(evt) => this.setState({device_id: evt.target.value})}
                            variant="outlined"
                            label="Device ID"/>
                        </Grid>
                        <Grid item xs={12}>
                          <Button className={classes.button}
                                  color="primary" 
                                  onClick={async () => await this.registerDevice()} variant="outlined"
                                  >
                                  Add Device
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12}><h2>Devices List</h2></Grid>
                      <Grid item xs={12}>
                        <Button onClick={() => this.retrieveDevices(true)}>Refresh</Button>
                      </Grid>
                      {this.props.devices.length > 0 ?
                          this.props.devices.map(dev => {
                              return (
                                  <Grid item xs={12} md={12}>
                                      <Paper className={classes.paper}>
                                          {`${dev.device.name} (${dev.device.operation_mode?.toString() || "Eish"})`}
                                      </Paper>
                                  </Grid>
                              );
                          })
                          : <CircularProgress className={classes.spinner} color="primary" />}
                    </Grid>
                </Grid>
            </div>);
    }
}

export default connector(withStyles(styles)(AdminDevices));