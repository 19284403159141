import { produce } from "immer";

import { ADD_CONTACT, ContactActionTypes, ContactsState, DELETE_CONTACT, SET_CONTACTS, UPDATE_CONTACT } from "./types";

const initialState: ContactsState = {
    contacts: [],
    initialised: false
}

export function contactsReducer(state = initialState, action: ContactActionTypes): ContactsState {
    switch (action.type) {
        case SET_CONTACTS:
            return {
                contacts: action.contacts.map(c => ({contact: c})),
                initialised: true
            };
        case ADD_CONTACT:
            return {
                ...state,
                contacts: [...state.contacts, {contact: action.contact}]
            };
        case UPDATE_CONTACT:
            return produce(state, draft => {
                let updatedContact = action.contact;
                let contact = draft.contacts.find(c => c.contact.contact_id == updatedContact.contact_id)!;
                contact.contact = {...updatedContact};
            });
        case DELETE_CONTACT:
            return produce(state, draft => {
                draft.contacts = draft.contacts.filter(c => c.contact.contact_id != action.contact_id)
            });
        default: return state;
    }
}