import { Device, Alert } from "components/client-area/common/models";

export interface DeviceItem {
    device: Device,
    alerts: Alert[] | undefined
}

export interface DeviceContact {
    device_id: string,
    device_contact_id: string | undefined
    contact_id: string
}

// State
export interface DeviceState {
    devices: DeviceItem[],
    devicesAdmin: DeviceItem[],
    devicesInitialised: boolean,
    devicesAdminInitialised: boolean
}

///// Actions
// Set devices
export const SET_DEVICES = "SET_DEVICES";

export interface SetDevicesAction {
    type: typeof SET_DEVICES,
    devices: Device[]
}

// Set devices
export const SET_DEVICES_ADMIN = "SET_DEVICES_ADMIN";

export interface SetDevicesAdminAction {
    type: typeof SET_DEVICES_ADMIN,
    devices: Device[]
}

// Update Device
export const UPDATE_DEVICE = "UPDATE_DEVICE";

export interface UpdateDeviceAction {
    type: typeof UPDATE_DEVICE,
    device: Device
}

// Link device contact
export const LINK_CONTACT = "LINK_CONTACT";

export interface LinkContactAction {
    type: typeof LINK_CONTACT,
    deviceContact: DeviceContact
}

// Unlink device contact
export const UNLINK_CONTACT = "UNLINK_CONTACT";

export interface UnlinkContactAction {
    type: typeof UNLINK_CONTACT,
    deviceContact: DeviceContact
}

// Update Alerts
export const UPDATE_ALERTS = "UPDATE_ALERTS";

export interface UpdateAlertsAction {
    type: typeof UPDATE_ALERTS,
    alerts: Alert[],
    deviceId : string
}

export type DeviceActionTypes = SetDevicesAction | SetDevicesAdminAction | UpdateDeviceAction | LinkContactAction | UnlinkContactAction | UpdateAlertsAction;