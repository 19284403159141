/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { API } from "aws-amplify";

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:057278cd-c335-44d7-a5e4-6b7202aa3118",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_8wyCiGhQr",
    "aws_user_pools_web_client_id": "6os2r17p0h1kfbu121kiv8t22o",
    "oauth": {},
    API: {
        endpoints: [
            {
                name: "IceApi",
                endpoint: "https://api.sateria.io/Prod"
            }
        ]
    }
};


export default awsmobile;
