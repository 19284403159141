import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { Provider } from 'react-redux';

import { createStore } from "redux";
import { rootReducer } from './redux/store';




Amplify.configure(awsExports);

const store = createStore(rootReducer, 
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());

// Turn of console logging in production
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
function composeWithDevTools(arg0: any): import("redux").StoreEnhancer<unknown, unknown> | undefined {
  throw new Error('Function not implemented.');
}

function applyMiddleware(arg0: any): any {
  throw new Error('Function not implemented.');
}

