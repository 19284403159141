import { Grid } from "@material-ui/core";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

export default function OrderConfirmation() {
    const checkoutOrder = useSelector((state: RootState) => state.order.checkout_order);
    
    return (
        <div>
            <Grid container spacing={3}>
                Your order has been confirmed or something similar. 

                Order ID: {checkoutOrder?.order.order_id}

                Order status: {checkoutOrder?.order.status.toString()}
            </Grid>
        </div>
    );
}