import React, { Component } from "react";
import { RootState } from "redux/store";
import { connect, ConnectedProps } from "react-redux";
import { Order, OrderStatus, SetOrdersAction, SET_ORDERS } from "redux/order/types";
import { API, Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Button, CircularProgress, Grid, Paper, withStyles } from "@material-ui/core";
import { Styles, WithStyles } from "@material-ui/core/styles/withStyles";
import OrderCard from "./order-card";

const mapState = (state: RootState) => ({
    orders: state.order.orders,
    ordersInitialised: state.order.ordersInitialised
});
const mapDispatch = {
    setOrders: (orders: Order[]): SetOrdersAction => ({ type: SET_ORDERS, orders: orders.map(o => ({order: o})) })
};

const styles: Styles<any, any, string> = theme => ({
    paper: {
        padding: '1.3em'
    },
    spinner: {
        position: 'fixed',
        top: '50%',
        left: '50%'
    }
});

const connector = connect(mapState, mapDispatch);

type AdminOrdersProps = ConnectedProps<typeof connector> & WithStyles<any>;
type AdminOrdersState = {showAwaitingShipmentSupport: boolean}

class AdminOrders extends Component<AdminOrdersProps, AdminOrdersState> {
    constructor(props: AdminOrdersProps) {
        super(props);
        this.state = {showAwaitingShipmentSupport: false};
    }

    retrieveOrders(override: boolean = false) {
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
          if (!this.props.ordersInitialised || override) {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            API.get("IceApi", "/api/order", {
                queryStringParameters: {adminRetrieve: true},
                headers: {Authorization: sessionToken}
            })
            .then((data: Order[]) => {
              this.props.setOrders(data);
              console.log("Success Orders!", data);
            })
            .catch(err => console.error("Error!", err)); 
            }
        })
        .catch(err => console.log("Error getting authenticated user", err)); 
      }
    
    componentDidMount() {
        this.retrieveOrders();
    }

    render() {
        const { classes } = this.props;

        return (
                <Grid container>
                    <Grid item xs={12}><h2>Orders</h2></Grid>
                    <Grid item xs={12}>
                        <Button onClick={() => this.retrieveOrders(true)}>Refresh</Button>
                        <Button onClick={() => this.setState({showAwaitingShipmentSupport: !this.state.showAwaitingShipmentSupport})}>Awaiting Shipment Support</Button>
                    </Grid>
                    <Grid container spacing={3} xs={12}>
                    {this.props.orders.length > 0 ?
                        this.props.orders
                        .filter(o => this.state.showAwaitingShipmentSupport
                            ? o.order.status == OrderStatus.INIT
                            : true)
                        .map(order => {
                             return (
                                <OrderCard order={order} key={order.order.order_id} />
                             );
                         })
                         : <CircularProgress className={classes.spinner} color="primary" />}
                    </Grid>
                </Grid>
        );
    }
}

export default connector(withStyles(styles)(AdminOrders));