import { Contact } from "components/client-area/common/models";

export interface ContactItem {
    contact: Contact
}

//State
export interface ContactsState {
    contacts: ContactItem[],
    initialised: boolean
}

///// Actions
// Set Contacts
export const SET_CONTACTS = "SET_CONTACTS";

export interface SetContactsAction {
    type: typeof SET_CONTACTS,
    contacts: Contact[]
}

// Add Contact
export const ADD_CONTACT = "ADD_CONTACT";

export interface AddContactAction {
    type: typeof ADD_CONTACT,
    contact: Contact
}

// Update Contact
export const UPDATE_CONTACT = "UPDATE_CONTACT";

export interface UpdateContactAction {
    type: typeof UPDATE_CONTACT,
    contact: Contact
}

// Delete Contact
export const DELETE_CONTACT = "DELETE_CONTACT";

export interface DeleteContactAction {
    type: typeof DELETE_CONTACT,
    contact_id: string
}

export type ContactActionTypes = SetContactsAction | AddContactAction | UpdateContactAction | DeleteContactAction;