import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import ReportProblemTwoToneIcon from '@material-ui/icons/ReportProblemTwoTone';
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import SecurityRoundedIcon from '@material-ui/icons/SecurityRounded';
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import InfoArea from "../../../components/InfoArea/InfoArea.js";

import styles from "../../../assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function InfoSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>0G Panic Buttons</h2>
          <h5 className={classes.description}>
           We offer a community-driven panic button solution that is both affordable and accessible.
           Our solution leverages a 0G network which can transmit a distress signal regardless of cellular or WiFi network availability.
           The physical panic buttons use an LPWAN (Low-Power, Wide Area Network) which boasts a battery life of up to 10 years without charge.
           The panic buttons are connected to our bespoke platform where customers can manage contacts that are alerted when the panic button is pressed.
           Elected contacts can be alerted via various channels - currently SMS, WhatsApp or automated distress calls. 
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Community Security"
              description="Our platform creates a community network of assistance in times of need, something that is important in remote areas where private security companies and SAPS fail to respond fast enough."
              icon={SecurityRoundedIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Trust"
              description="Sateria runs on state of the art cloud technology, keeping your information secure and alerts instant."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Farm Attacks Must Fall"
              description="The SigFox 0G network thas coverage where there is no cellphone signal. Send alterts to loved ones and community security contacts on multiple channels."
              icon={ReportProblemTwoToneIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
