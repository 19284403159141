export interface CartProduct{
    product_id : string,
    img_uri : string,
    // description : string,
    name : string,
    price_per_month : number,
    device_cost: number
}

export interface CartState {
    products : {[product_id: string]: CartProduct},
    product_count : {[product_id: string]: number},
    count : number
}

///// Actions
// Add to cart
export const ADD_TO_CART = "ADD_TO_CART";

export interface AddProductToCart {
    type: typeof ADD_TO_CART,
    product: CartProduct
}

// Remove from cart
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export interface RemoveProductFromCart {
    type: typeof REMOVE_FROM_CART,
    product: CartProduct
}

// Empty cart
export const EMPTY_CART = "EMPTY_CART";

export interface EmptyCartAction {
    type: typeof EMPTY_CART
}

export type CartActions = AddProductToCart | RemoveProductFromCart | EmptyCartAction;