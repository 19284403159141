import { RootState } from "../../../redux/store";
import { CartProduct, AddProductToCart, RemoveProductFromCart, ADD_TO_CART, REMOVE_FROM_CART } from "../../../redux/cart/types";
import { connect, ConnectedProps } from "react-redux";
import React, { Component } from "react";
import md5 from "md5";

import HeaderLinks from "components/Header/HeaderLinks";
import Header from "components/Header/Header";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Divider, Grid, Hidden, Link, Radio, Step, StepLabel, Stepper, Typography } from "@material-ui/core";
import { withStyles, WithStyles } from '@material-ui/styles';
import { Styles } from "@material-ui/core/styles/withStyles";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";
import classNames from "classnames";
import { API, Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { CheckoutOrderItem, CheckoutOrderProduct, Order, OrderAttachedItem, OrderStatus } from "redux/order/types";

const mapState = (state: RootState) => ({
    cart: state.cart,
    checkout_order: state.order.checkout_order?.order
});

const mapDispatch = {
    addItemToCart: (product: CartProduct): AddProductToCart => ({ type: ADD_TO_CART, product: product }),
    removeItemFromCart: (product: CartProduct): RemoveProductFromCart => ({ type: REMOVE_FROM_CART, product: product })
}

type PaymentComponentProps = {
    paymentRef: React.RefObject<any>
};

type PaymentComponentState = {
    activeStep: number
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const styles: Styles<any, any, string> = theme => ({
    paper: {
        padding: '1.3em'
    },
    root: {
        // width: '100%',
    },
    button: {
        // marginRight: theme.spacing(1),
    },
    instructions: {
        // marginTop: theme.spacing(1),
        // marginBottom: theme.spacing(1),
    },
    imgFluid: {
        maxWidth: "100%",
        height: "auto"
    },
    imgRounded: {
        borderRadius: "6px !important"
    },
    imgRoundedCircle: {
        borderRadius: "50% !important"
    },
    imgRaised: {
        boxShadow:
            "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    imgGallery: {
        width: "100%",
        marginBottom: "2.142rem"
    },
});

class PaymentComponent extends Component<PropsFromRedux & PaymentComponentProps & WithStyles<any>, PaymentComponentState>{
    constructor(props: PropsFromRedux & PaymentComponentProps & WithStyles<any>) {
        super(props);

        this.state = {
            activeStep: 0
        }
    }

    calculateTotalMonthlyCost(): number {
        let total: number = 0.0;
        for (let product_id in this.props.cart.products) {
            total += this.props.cart.products[product_id].price_per_month * this.props.cart.product_count[product_id];
        }
        return total;
    }

    calculateTotalOnceOffCost(): number {
        return this.calculateTotalMonthlyCost() + this.calculateShipping();
    }

    calculateShipping(): number {
        return 150.00;
    }

    componentDidMount() {
    }

    // createOrder() {
    //     return Auth.currentAuthenticatedUser()
    //         .then((user: CognitoUser) => {
    //             let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
    //             return API.post('IceApi', '/api/order', {
    //                 body: this.state.order,
    //                 headers: { Authorization: sessionToken }
    //             })
    //                 .then(data => {
    //                     console.log("success!", data.message);
    //                     this.setState({ order: { ... this.state.order, order_id: data.order_id } })
    //                 })
    //                 .catch(err => {
    //                     console.error(err);
    //                 });
    //         })
    // }

    render() {
        const { classes } = this.props;
        const imageClasses = classNames(
            classes.imgRaised,
            classes.imgFluid
        );

        const { 
            REACT_APP_PAYFAST_URL, 
            REACT_APP_PAYFAST_MERCHANT_ID,
            REACT_APP_PAYFAST_MERCHANT_KEY,
            REACT_APP_PAYFAST_RETURN_URL,
            REACT_APP_PAYFAST_PASSPHRASE
        } = process.env;

        // const paymentDetails = {
        //     merchant_id: REACT_APP_PAYFAST_MERCHANT_ID!,
        //     merchant_key: REACT_APP_PAYFAST_MERCHANT_KEY!,
        //     return_url: `http://${REACT_APP_PAYFAST_RETURN_URL!}`,
        //     cancel_url: "https://api.sateria.io/Prod/api/payment/payfast/notify",
        //     notify_url: "https://api.sateria.io/Prod/api/payment/payfast/notify",
        //     m_payment_id: this.state.order.order_id!!,
        //     // amount: this.state.order.total.toString(),
        //     amount: "10",
        //     item_name: this.state.order.order_id!!,
        //     item_description: this.state.order.order_id!!,
        //     subscription_type: "1",
        //     recurring_amount: "99",
        //     frequency: "3",
        //     cycles: "1",
        //     passphrase: REACT_APP_PAYFAST_PASSPHRASE!
        // };

        // const params = new URLSearchParams(paymentDetails);

        // Create an MD5 signature of it.
        // const MD5Signature = md5(params.toString())
        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Typography component="div">
                            <Box fontWeight="fontWeightBold">
                                Order Details
                            </Box>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {this.props.checkout_order?.items.map((row: CheckoutOrderProduct, index: number) =>
                            <Paper key={index} className={classes.paper}>
                                <Grid container spacing={1}>
                                    {/* <Grid item xs={1} md={1}>
                                        <img src={"https://sateria.io/" + row.product.img_uri} alt="..." className={imageClasses} />
                                    </Grid>
                                    <Grid item xs={10} md={10}>
                                        <Box fontWeight="fontWeightBold" >
                                            {row.product.name}
                                        </Box>
                                        <Typography color="textSecondary" gutterBottom>
                                            Qty: {row.count}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} md={1}>
                                        <Typography color="textSecondary" gutterBottom>
                                            R{row.product.price_per_month} p/m
                                        </Typography>
                                    </Grid> */}
                                    {row.product_id}
                                </Grid>
                            </Paper>
                        )}
                    </Grid>
                    {/* <Grid item xs={12} md={12}>
                        <Typography component="div">
                            <Box fontWeight="fontWeightBold">
                                Delivery
                            </Box>
                        </Typography>
                    </Grid> */}
                    {/* <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={1}>
                                <Grid item xs={1} md={1}>
                                    <Radio checked={true} />
                                </Grid>
                                <Grid item xs={10} md={10}>
                                    <Box fontWeight="fontWeightBold" >
                                        Standard Delivery
                                    </Box>
                                    <Typography className={classes.addressText} color="textPrimary" gutterBottom>
                                        Delivery within 2 -3 business days
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} md={1}>
                                    <Typography className={classes.addressText} color="textSecondary" gutterBottom>
                                        R150
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid> */}
                </Grid>
                
                {/* <form action={REACT_APP_PAYFAST_URL} method="POST">
                    <input type="hidden" name="merchant_id" value={paymentDetails.merchant_id} />
                    <input type="hidden" name="merchant_key" value={paymentDetails.merchant_key} />
                    <input type="hidden" name="return_url" value={paymentDetails.return_url} />
                    <input type="hidden" name="cancel_url" value={paymentDetails.cancel_url} />
                    <input type="hidden" name="notify_url" value={paymentDetails.notify_url} />
                    <input type="hidden" name="m_payment_id" value={paymentDetails.m_payment_id} />
                    <input type="hidden" name="amount" value={paymentDetails.amount} />
                    <input type="hidden" name="item_name" value={paymentDetails.item_name} />
                    <input type="hidden" name="item_description" value={paymentDetails.item_description} />
                    <input type="hidden" name="subscription_type" value={paymentDetails.subscription_type} />
                    <input type="hidden" name="recurring_amount" value={paymentDetails.recurring_amount} />
                    <input type="hidden" name="frequency" value={paymentDetails.frequency} />
                    <input type="hidden" name="cycles" value={paymentDetails.cycles} />
                    <input type="hidden" name="passphrase" value={paymentDetails.passphrase} />
                    <input type="hidden" name="signature" value={MD5Signature} />
                    <input style={{ display: 'none' }} ref={this.props.paymentRef} type="submit" />
                </form> */}
            </div>
        );
    }
}
//action="https://sandbox.payfast.co.za/eng/process"
export default connector(withStyles(styles)(PaymentComponent));