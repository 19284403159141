import React, { useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API } from "aws-amplify";
import { ADD_TO_CART } from "redux/cart/types";
import PanicButtonFixedProduct from "../../components/product/PanicButtonFixedProduct";
import PanicButtonAdvancedProduct from "../../components/product/PanicButtonAdvancedProduct";
import ProductComponent from "../../components/product/product";


const useStyles = makeStyles(styles);

export default function ProductPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );


  const [foundProductDetails, setFoundProductDetails] = useState(false);
  const [dispatched, setDispatched] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  
  let addProductToCart = () => {
    if(productDetails != null){
      dispatch({type: ADD_TO_CART , product: productDetails})
    }
  }

  if (productDetails != null && productDetails.product_id != props.match.params.id && dispatched) {
    console.log("1");
    setFoundProductDetails(false);
    setProductDetails(null);
    setDispatched(false);
  }
  
  if(!dispatched){
    console.log("2");
    setDispatched(true);
    API.get("IceApi", "/api/product/"+props.match.params.id)
    .then((data) => {
      setFoundProductDetails(true);
      setProductDetails(data);
    })
    .catch(err =>{
      setFoundProductDetails(true);
    });
  }

  const productInfoSection = (
      <div>
        <Header
          color="transparent"
          brand="Sateria"
          rightLinks={<HeaderLinks />}
          fixed
          {...rest}
        />
        <Parallax small filter image={require("assets/img/profile-bg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
      {productDetails != null ?
        <ProductComponent productInfo={(() => {
          switch (props.match.params.id) {
            case "OgSPFixed": return new PanicButtonFixedProduct(productDetails);
            // case "OgSPAdvanced": return new PanicButtonAdvancedProduct(productDetails);
            default: return null;
          }
        })()} />
      : null}
    </div>
    <Footer />
    </div>
  );

  const loadingSection = (
    <div>
    <Header
      color="white"
      brand="Sateria"
      rightLinks={<HeaderLinks />}
      fixed
    /> 
    <CircularProgress style={{position: 'fixed', top: '50%', left: '50%' }} />
  </div>);

const productNotFound = (
  <div>
  <Header
    color="white"
    brand="Sateria"
    rightLinks={<HeaderLinks />}
    fixed
  /> 
  <h4 style={{position: 'fixed', top: '50%', left: '50%' }} >
    Product not found.  
  </h4>
</div>);

  if (foundProductDetails){
    if (productDetails == null){
      return productNotFound;
    }

    return productInfoSection;
  }
  else{
    return loadingSection;
  }
}
