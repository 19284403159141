import { AuthActionTypes, AuthState, SET_LOGGED_IN } from "./types";

const initialState: AuthState = {
    authInitRun: false,
    isLoggedIn: false,
    user: undefined
}

export function authReducer(
    state = initialState,
    action: AuthActionTypes) 
    {
        switch (action.type) {
            case SET_LOGGED_IN:
                return {
                    ...state,
                    isLoggedIn: action.isLoggedIn,
                    user: action.authUser,
                    authInitRun: action.authInitRun
                }
            default: return state;
        }
    }