import { RootState } from "../../redux/store";
import { CartProduct, AddProductToCart, RemoveProductFromCart, ADD_TO_CART, REMOVE_FROM_CART } from "../../redux/cart/types";
import { connect, ConnectedProps } from "react-redux";
import React, { Component } from "react";

import HeaderLinks from "components/Header/HeaderLinks";
import Header from "components/Header/Header";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Grid, Hidden, Typography } from "@material-ui/core";
import { withStyles, WithStyles } from '@material-ui/styles';
import { Styles } from "@material-ui/core/styles/withStyles";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle";
import { Link } from "react-router-dom";

const mapState = (state: RootState) => ({
    cart: state.cart,
});

const mapDispatch = {
    addItemToCart: (product: CartProduct): AddProductToCart  => ({type: ADD_TO_CART, product: product}),
    removeItemFromCart: (product: CartProduct): RemoveProductFromCart  => ({type: REMOVE_FROM_CART, product: product})
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const styles:Styles<any, any, string> = theme => ({
  paper: {
    padding: '1.3em'
  }
});


class CartPage extends Component<PropsFromRedux & WithStyles<any>>{
    constructor(props: PropsFromRedux & WithStyles<any>){
        super(props);
    }

    calculateTotalMonthlyCost() : number{
      let total: number = 0.0;
      for(let product_id in this.props.cart.products){
        total += this.props.cart.products[product_id].price_per_month * this.props.cart.product_count[product_id];
      }
      return total;
    }

    calculateTotalDeviceCost() : number{
      let total: number = 0.0;
      for(let product_id in this.props.cart.products){
        total += this.props.cart.products[product_id].device_cost * this.props.cart.product_count[product_id];
      }
      return total;
    }

    render() {
      const { classes } = this.props;
        const rows : {product: CartProduct, count: number}[] = [];
        
        for(let product_id in this.props.cart.products){
            rows.push({product: this.props.cart.products[product_id], count: this.props.cart.product_count[product_id]})
        }
        
        let cartComponents = (
        

        <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {rows.map((row:{product: CartProduct, count: number}) => (
                  <TableRow key={row.product.product_id}>
                    <TableCell align="center">
                    <img src={"https://sateria.io/"+row.product.img_uri} alt="..." width="100" height="100" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.product.name}
                    </TableCell>
                    <TableCell align="right">
                        {row.count}
                        <IconButton color="secondary" aria-label="add an alarm" onClick={()=>{this.props.addItemToCart(row.product)}}>
                          <AddIcon/>
                        </IconButton>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={()=>{this.props.removeItemFromCart(row.product)}}>
                          <RemoveIcon/>
                        </IconButton>
                    </TableCell>
                
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );

          
        if(this.props.cart.count < 1){
          cartComponents = (<Typography className={classes.title} color="textPrimary" gutterBottom>
                                  You have no items in your cart.           
                        </Typography>);
        }

        return (
            <div>
                <Header
                    color="white"
                    brand="Sateria"
                    rightLinks={<HeaderLinks />}
                /> 
                <Grid container spacing={3}>
                    <Grid item xs={1} md={1}></Grid>
                    <Grid item xs={7} md={7}>
                    <Paper className={classes.paper}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Shopping Cart
                    </Typography>  
                      {cartComponents}
                      </Paper>
                    </Grid>
                    {this.props.cart.count < 1 ? <div></div> :
                    <Grid item xs={3} md={3} >
                    <Paper className={classes.paper}>
                        <Box fontWeight="fontWeightBold" >
                        Cart Summary
                          </Box>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Total ({this.props.cart.count} items)
                        </Typography>
                        <Typography component="div">
                          <Box fontWeight="fontWeightBold">
                            R{this.calculateTotalDeviceCost()}
                          </Box>
                        </Typography>
                        <Typography className={classes.title} color="textPrimary" gutterBottom>
                          Monthly Subscription Cost
                        </Typography>
                        <Typography component="div">
                          <Box fontWeight="fontWeightBold">
                            R{this.calculateTotalMonthlyCost()}
                          </Box>
                        </Typography>

                      <Box textAlign='center'>
                        <Link to="/checkout">
                          <Button size="large" variant="contained" color="primary">
                              Checkout
                          </Button>
                        </Link>
                      </Box>
                      </Paper>
                    </Grid>}
                </Grid>

            </div>
        );
    }
}

export default connector(withStyles(styles)(CartPage));