import { Button, Grid, Paper, TextField } from "@material-ui/core";
import React, { ChangeEvent, ChangeEventHandler, Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Order, OrderStatus, UpdateOrderAction, UPDATE_ORDER } from "redux/order/types";
import { withStyles, WithStyles } from '@material-ui/styles';
import { Styles } from "@material-ui/core/styles/withStyles";
import { RootState } from "redux/store";
import { connect, ConnectedProps } from "react-redux";
import { Auth, API } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { toast } from 'react-toastify';
import EditableDisplayTextField from "components/common/components/EditableDisplayTextField/EditableDisplayTextField";

const styles: Styles<any, any, string> = theme => ({
    paper: {
        width: '100%',
        padding: '1.3em'
    }
});

// Redux
const mapState = (state: RootState, props: RouteComponentProps) => ({
    order: state.order.orders.find(o => o.order.order_id == props.match.params['id'])
});
const mapDispatch = {
    updateOrder: (order: Order): UpdateOrderAction => ({ type: UPDATE_ORDER, order: order })
};

const connector = connect(mapState, mapDispatch);

interface OrderItemInfo {
    attached_devices: string[],
    number_of_items: number
}

type OrderViewProps = RouteComponentProps & ConnectedProps<typeof connector> & WithStyles<any>;
type OrderViewState = {customShippingAmount: number|null, relativeShipmentDays: number|null};

class OrderView extends Component<OrderViewProps, OrderViewState> {

    constructor(props: OrderViewProps) {
        super(props);
        this.state = {customShippingAmount: null, relativeShipmentDays: null};
    }

    attachDevice(productId: string) {
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            API.put("IceApi", "/api/order", {
              headers: {Authorization: sessionToken},
              body: {operation: "attach_device", order_id: this.props.order?.order.order_id, product_id: productId}
            })
            .then((data: Order) => {
              console.log("Success Attach Device!", data);
              toast.success("Device successfully attached", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                }); 
                this.props.updateOrder(data);
                // this.setState({orderItemInfo: null});
            })
            .catch(err => console.error("Error!", err)); 
            })
        .catch(err => console.log("Error getting authenticated user", err)); 
    }

    shipOrder() {
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            API.put("IceApi", "/api/order", {
              headers: {Authorization: sessionToken},
              body: {operation: "ship_order", order_id: this.props.order?.order.order_id}
            })
            .then((data: Order) => {
              console.log("Success Ship Order!", data);
              toast.success("Order successfully shipped", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                }); 
                this.props.updateOrder(data);
            })
            .catch(err => console.error("Error!", err)); 
            })
        .catch(err => console.log("Error getting authenticated user", err)); 
    }

    getOrderItemInfo(): {[product_id: string]: OrderItemInfo} {
        console.log("getOrderItemInfo");
        if (!this.props.order) return {};

        let newOrderItemInfo: {[product_id: string]: OrderItemInfo} = {};
        this.props.order.order.items.forEach(item => {
            if (!(item.product_id in newOrderItemInfo)) {
                // Check if product_id has been added
                newOrderItemInfo[item.product_id] = {
                    attached_devices: [],
                    number_of_items: 0
                }
            }
            // Add device ID to list if already attached
            if (item.attached_device_id) {
                newOrderItemInfo[item.product_id].attached_devices.push(item.attached_device_id);
            }
            newOrderItemInfo[item.product_id].number_of_items += 1;
        });

        return newOrderItemInfo;
    }

    updateShipmentInfo() {
        Auth.currentAuthenticatedUser()
        .then((user: CognitoUser) => {
            let sessionToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
            API.put("IceApi", "/api/order", {
                headers: {Authorization: sessionToken},
                body: {
                    operation: "update_shipping_info", 
                    order_id: this.props.order?.order.order_id,
                    shipping_info: {
                        customShippingAmount: this.state.customShippingAmount, 
                        relativeShipmentDays: this.state.relativeShipmentDays
                }}
              })
              .then((data: Order) => {
                console.log("Success Update Shipment Info!", data);
                toast.success("Order shipping info updated", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  }); 
                  this.props.updateOrder(data);
              })
              .catch(err => console.error("Error!", err)); 
        })
        .catch(err => console.log("Error getting authenticated user", err)); 
    }

    componentDidMount() {
    }

    render() {
        console.log("Order", this.props);

        const { classes } = this.props;

        let orderItemInfo = this.getOrderItemInfo();
        let orderStatus = this.props.order?.order.status;
        let canAttachOrderItem = orderStatus == OrderStatus.PAID;
        let canShipOrder = Object.keys(orderItemInfo).every(p_id => {
            let orderProduct = orderItemInfo[p_id];
            return orderProduct.attached_devices.length == orderProduct.number_of_items;
        });

        console.log("canAttachOrderItem", canAttachOrderItem);

        return (
            <Grid container xs={12}>
                <Paper className={classes.paper}>
                    <Grid item xs={12}><h2>Order: {this.props.order?.order.order_id}</h2></Grid>
                    <Grid item xs={12}><h3>Status: {orderStatus}</h3></Grid>
                    {this.props.order?.order.status == OrderStatus.AWAITING_SHIPMENT_SUPPORT ?
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                label="Shipping Amount"
                                value={this.state.customShippingAmount}
                                onChange={(val: ChangeEvent<HTMLTextAreaElement>) => {
                                    this.setState({customShippingAmount: Number(val.target.value)});
                                }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Shipping Days"
                                value={this.state.relativeShipmentDays}
                                onChange={(val: ChangeEvent<HTMLTextAreaElement>) => {
                                    this.setState({relativeShipmentDays: Number(val.target.value)});
                                }} />
                        </Grid>
                        <Grid item xs={12}>
                        <Button onClick={this.updateShipmentInfo.bind(this)}>Update</Button>
                        </Grid>
                    </Grid>
                    : null}
                    <Grid item xs={12}><h3>Attached Devices</h3></Grid>
                    <Grid container xs={12}>
                        {Object.keys(orderItemInfo).map(product_id => {
                            let order_item = orderItemInfo[product_id];
                            let items_to_attach = order_item.number_of_items - order_item.attached_devices.length;
                            return (
                                <Grid container xs={12}>
                                    <Grid item xs={6}>{`${product_id} (${order_item.number_of_items} item(s))`}</Grid>
                                    <Grid item xs={5}>{items_to_attach > 0 ? 
                                        <Button 
                                            variant="outlined"
                                            disabled={!canAttachOrderItem}
                                            onClick={() => this.attachDevice(product_id)}>Attach</Button>
                                        : null}</Grid>
                                    {order_item.attached_devices.map(dev => <Grid item xs={12}>{dev}</Grid>)}
                                </Grid>
                            );
                        })
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {orderStatus != OrderStatus.SHIPPED ?
                            <Button disabled={!canShipOrder} variant="outlined" onClick={() => this.shipOrder()}>Ship Order</Button> :
                            <h4>ORDER IS SHIPPED (MORE INFO TBD)</h4>
                        }
                        
                    </Grid>
                </Paper>
            </Grid>
        );
    }
}

export default connector(withStyles(styles)(OrderView));