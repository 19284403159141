import React, { Component } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import { CircularProgress, IconButton, InputAdornment, TextField } from '@material-ui/core';
import withStyles, { Styles, WithStyles } from '@material-ui/core/styles/withStyles';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles:Styles<any, any, string> = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    }
  });

enum FieldState {
    VIEW,
    EDIT,
    IN_PROGRESS
}

type EditableDisplayTextFieldProps = {
    value: string,
    label: string,
    updateFunc: (val: string) => Promise<void>,
    customClassName: string,
    customRootClassName: string
};  


class EditableDisplayTextField extends Component<EditableDisplayTextFieldProps &  WithStyles<any>, {fieldState: FieldState, curValue: string}> {
    constructor(props: EditableDisplayTextFieldProps &  WithStyles<any>) {
        super(props);
        this.state = {fieldState: FieldState.VIEW, curValue: props.value};
    }

  render(){
    let update = ()=>{
        this.setState({fieldState: FieldState.IN_PROGRESS});
        this.props.updateFunc(this.state.curValue)
                    .then(() => {
                        this.setState({fieldState: FieldState.VIEW});
                        toast.success(this.props.label + ' updated successfully.', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            });
                    })
                    .catch((e) => {
                        this.setState({fieldState: FieldState.VIEW, curValue : this.props.value});
                        console.log(e);
                        toast.error('An error occured attempting to update ' + this.props.label + ". Please try again.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            });
                    });
    }
    const { classes } = this.props;
    const inputRef : React.RefObject<HTMLInputElement> = React.createRef()
    return (<div className={this.props.customRootClassName || classes.root}>
       
       <TextField className={this.props.customClassName}
            size="small"
            variant="outlined"
            label={this.props.label}
            inputRef={inputRef}
            defaultValue={this.props.value}
            onChange={(evt) => this.setState({curValue: evt.target.value})}
            InputProps={{
                endAdornment : <InputAdornment position="end">{this.state.fieldState == FieldState.EDIT ? 
                    <IconButton color="primary" onClick={() => {
                            this.setState({fieldState: FieldState.IN_PROGRESS});
                            if (window.getSelection != null) {
                                window.getSelection()?.removeAllRanges();
                            }
                            else if (document.getSelection() != null){
                                document.getSelection()?.removeAllRanges();
                            } 
                            update();
                        }} className={classes.iconButton}>
                        <DoneIcon />
                    </IconButton> : this.state.fieldState == FieldState.VIEW ?
                    <IconButton color="primary" onClick={() =>{
                        this.setState({fieldState: FieldState.EDIT});
                        inputRef.current && inputRef.current.select();
                     }} className={classes.iconButton}>
                        <EditIcon />
                    </IconButton> : 
                    <CircularProgress size="1.5rem" />
                    }</InputAdornment>,
                readOnly : this.state.fieldState == FieldState.EDIT ? false : true
            }}
            onKeyDown={(e)=>{
                if (e.key === 'Enter') {
                    update()
                }
            }}/>    
    </div>);
  }
}

export default withStyles(styles)(EditableDisplayTextField);