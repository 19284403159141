import { CircularProgress, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { Alert } from "../common/models";
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { NotificationImportantRounded } from "@material-ui/icons";
import withStyles, { Styles, WithStyles } from "@material-ui/core/styles/withStyles";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Paper from '@material-ui/core/Paper';

type AlertDashboardPropertes = {
  alerts: Alert[] | undefined
}

type AlertDashboardState = {
  selectedAlert: Alert | undefined
}

type TablePagitationState = {
  page: number,
  rowsPerPage: number
}

const styles: Styles<any, any, string> = theme => ({
  spinner: {
    position: 'relative',
    top: '50%',
    left: '50%'
  },
  root: {
    // flexShrink: 0,
  },
  table: {
    minWidth: 500,
  },
});

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}


class AlertDashboard extends Component<AlertDashboardPropertes & WithStyles<any> & TablePaginationActionsProps, AlertDashboardState & TablePagitationState> {

  constructor(props: AlertDashboardPropertes & WithStyles<any> & TablePaginationActionsProps) {
    super(props);
    this.state = {
      selectedAlert: undefined,
      page: 0,
      rowsPerPage: 5
    };
    this.tablePaginationActions = this.tablePaginationActions.bind(this);
  }

  tablePaginationActions(props: TablePaginationActionsProps) {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => { props.onChangePage(event, 0) }}
          disabled={props.page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => { props.onChangePage(event, props.page - 1) }} disabled={props.page === 0} aria-label="previous page">
          <KeyboardArrowLeft />
        </IconButton>

        <IconButton
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => { props.onChangePage(event, props.page + 1) }}
          disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => { props.onChangePage(event, Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1)) }}
          disabled={props.page >= Math.ceil(props.count / props.rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }

  customPaginationActionsTable() {
    const { classes } = this.props;

    if (this.state.selectedAlert === undefined) {
      return (<div></div>);
    }

    let rows = this.state.selectedAlert.events;

    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, rows.length - this.state.page * this.state.rowsPerPage);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      this.setState({ page: newPage });
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) });
    };

    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableBody>
            {(this.state.rowsPerPage > 0
              ? rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row.tmstamp}>
                <TableCell component="th" scope="row">
                  {new Date(row.tmstamp).toLocaleString()}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.description}
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={2}
                count={rows.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={this.tablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const { classes } = this.props;

    if (this.props.alerts === undefined) {
      return (
        <CircularProgress className={classes.spinner} color="primary" />);
    }

    if (this.props.alerts.length == 0) {
      return (
        <Grid container spacing={1}>
          <Grid item xs={4} md={4}>
            <Typography color="textSecondary" gutterBottom>
              No alerts sent on this device
        </Typography></Grid></Grid>);
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography color="textSecondary" gutterBottom>
            Alerts
                    </Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <FixedSizeList height={200} itemSize={46} itemCount={this.props!.alerts.length} moreItemsLoading={true} >
            {(index, style) => {
              if (this.props.alerts == undefined) {
                return (<ListItem button style={style} key={index}>
                  <ListItemText primary="" />
                </ListItem>)
              }
              let alert = this.props.alerts[index.index]
              return (
                <ListItem button style={style} key={index} onClick={() => {
                  this.setState({ selectedAlert: alert });
                }}>
                  <ListItemIcon>
                    <NotificationImportantRounded color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary={new Date(this.props.alerts[index.index].tmstamp).toLocaleString()} />
                </ListItem>
              );
            }}
          </FixedSizeList>
        </Grid>
        <Grid item xs={8} md={8}>
          {/* {this.state.selectedAlert == undefined ? <div></div> : <FixedSizeList height={200} itemSize={40} itemCount={this.state.selectedAlert.events.length}>
                        {(index, style ) => {
                            let event = this.state.selectedAlert?.events[index.index]
                            return (
                            <ListItem button style={style} key={index}>
                                <ListItemText primary={event?.type+ " | " + event?.description} />
                            </ListItem>
                            );
                        }}
                        </FixedSizeList>} */}
          {this.state.selectedAlert == undefined ? <div></div> : this.customPaginationActionsTable()}


        </Grid>
      </Grid>

    );
  }
}

export default withStyles(styles)(AlertDashboard);