import { Button, Collapse, Grid, IconButton, Paper, Tooltip, withStyles } from "@material-ui/core";
import { Styles, WithStyles } from "@material-ui/core/styles/withStyles";
import { ExpandMoreRounded, ExpandLessRounded } from "@material-ui/icons";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Order, OrderItem } from "redux/order/types";

const styles: Styles<any, any, string> = theme => ({
    paper: {
        padding: '1.3em'
    }
});

type OrderCardProps = {order: OrderItem} & WithStyles<any>;
type OrderCardState = {};

class OrderCard extends Component<OrderCardProps, OrderCardState> {
    constructor(props: OrderCardProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        const order = this.props.order;

        return (
            <Grid item xs={12} md={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={1} xs={12}>
                        <Grid xs={11}>
                                {`${order.order.order_id} (${order.order.status?.toString() || "Eish"})`}
                        </Grid>
                        <Grid xs={1}>
                            <Link to={`/admin/order/${order.order.order_id}`}>
                                <Button>View</Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    }
}

export default withStyles(styles)(OrderCard);