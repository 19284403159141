import produce from "immer";
import { DeviceActionTypes, DeviceState, LINK_CONTACT, SET_DEVICES, SET_DEVICES_ADMIN, UNLINK_CONTACT, UPDATE_ALERTS, UPDATE_DEVICE } from "./types";

const initialState: DeviceState = {
    devices: [],
    devicesInitialised: false,
    devicesAdmin: [],
    devicesAdminInitialised: false
}

export function deviceReducer(state = initialState, action: DeviceActionTypes): DeviceState {
    switch (action.type) {
        case SET_DEVICES:
            return {
                ...state,
                devices: [...action.devices.map(d => ({device: {...d}, alerts: undefined}))],
                devicesInitialised: true
            }
        case SET_DEVICES_ADMIN:
            return {
                ...state,
                devicesAdmin: [...action.devices.map(d => ({device: {...d}, alerts: undefined}))],
                devicesAdminInitialised: true
            }
        case UPDATE_DEVICE:
            return produce(state, draft => {
                let updatedDevice = action.device;
                let device = draft.devices.find(d => d.device.device_id == updatedDevice.device_id)!;
                device.device = {...updatedDevice};
            });
        case LINK_CONTACT:
            return produce(state, draft => {
                console.log(state);
                let device = draft.devices.find(d => d.device.device_id == action.deviceContact.device_id)!.device;
                device.contacts.push(action.deviceContact);
                return draft;
            });
        case UNLINK_CONTACT:
            return produce(state, draft => {
                let device = draft.devices.find(d => d.device.device_id == action.deviceContact.device_id)!.device;
                device.contacts = device.contacts.filter(c => c.device_contact_id != action.deviceContact.device_contact_id);
            });
        case UPDATE_ALERTS:
            return produce(state, draft => {
                if(action.alerts.length === undefined){
                    return draft;
                }
                
                let device_item = draft.devices.find(d => d.device.device_id == action.deviceId);
                if(device_item != null){
                    device_item.alerts = action.alerts;
                } 
            });    
        default: return state;
    }
}