import { CognitoUser } from "amazon-cognito-identity-js";

// State
export interface AuthState {
    isLoggedIn: Boolean,
    user?: CognitoUser,
    authInitRun: boolean
}

// Actions
export const SET_LOGGED_IN = "SET_LOGGED_IN";

export interface SetLoggedInAction {
    type: typeof SET_LOGGED_IN
    isLoggedIn: Boolean,
    authUser?: CognitoUser,
    authInitRun: boolean
}

export type AuthActionTypes = SetLoggedInAction;