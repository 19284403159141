import React, { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "../../redux/store";
import { Router } from "@material-ui/icons";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import { Grid, Hidden, List, ListItemIcon, ListItemText } from "@material-ui/core";
import { withStyles, WithStyles } from '@material-ui/styles';
import { Styles } from "@material-ui/core/styles/withStyles";
import AdminDevices from "./devices/devices";
import AdminOrders from "./orders/orders";
import OrderView from "./orders/order";
import { AdminView } from "./enums";
import { Link, Route, RouteComponentProps, Switch, useRouteMatch } from "react-router-dom";

const mapState = (state: RootState) => ({
  authUser: state.auth.user,
  devices: state.devices.devicesAdmin,
  devicesInitialised: state.devices.devicesAdminInitialised
})
  
const mapDispatch = {}

const connector = connect(mapState, mapDispatch);

const styles:Styles<any, any, string> = theme => ({
  fields : {
      width: '40ch'
  },
  button : {
    marginTop: 20
  }
});

function ListItemLink(props:any) {
  return <Link to={props.path} children={props.children} />;
  {/* <ListItem button component="a"  />; */}
}

type PropsFromRedux = ConnectedProps<typeof connector> & RouteComponentProps;

type AdminComponentState = {view: AdminView};
class AdminComponent extends Component<PropsFromRedux & WithStyles<any>, AdminComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {view: AdminView.ORDERS};
    }
      
    render() {
      const { classes } = this.props;
      let path = this.props.match.path;
      let url = this.props.match.url;
      
        return (
            <div>
               <Header
                    color="white"
                    brand="Sateria"
                    rightLinks={<HeaderLinks />}
                /> 
                  <Grid container spacing={3}>
                    <Hidden smDown>
                      <Grid item md={2}>
                        <List component="nav" aria-label="main">
                            <ListItemLink path={`${url}/`}>
                                <ListItemIcon>
                                    <Router />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItemLink>
                            <ListItemLink path={`${url}/orders`}>
                                <ListItemIcon>
                                    <Router />
                                </ListItemIcon>
                                <ListItemText primary="Orders" />
                            </ListItemLink>
                            <ListItemLink path={`${url}/devices`}>
                                <ListItemIcon>
                                    <Router />
                                </ListItemIcon>
                                <ListItemText primary="Devices" />
                            </ListItemLink>
                        </List>
                      </Grid>
                    </Hidden>
                    <Grid item xs={10} md={8}>
                      <Grid container spacing={3} xs={12}>
                          {/* {(() => {
                            switch (this.state.view) {
                              case AdminView.DEVICES: return <AdminDevices />;
                              case AdminView.ORDERS: return <AdminOrders />;
                            }
                          })()} */}
                          <Switch>
                            <Route exact path={`${path}`}>
                              <h3>ADMIN DASHBOARD (TBC)</h3>
                            </Route>
                            <Route exact path={`${path}/orders`}>
                              <AdminOrders />
                            </Route>
                            <Route exact path={`${path}/devices`}>
                              <AdminDevices />
                            </Route>
                            <Route exact path={`${path}/order/:id`} component={OrderView}>
                            </Route>
                          </Switch>
                      </Grid>
                      
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default connector(withStyles(styles)(AdminComponent));