import { CircularProgress } from "@material-ui/core";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

export abstract class ProtectedRouteBase<T extends {isLoggedIn: Boolean, authInitRun: boolean}> extends Route<RouteProps & T> {
  render() {
    if (!this.props.authInitRun) {
      console.log("!this.props.authInitRun", this.props);
      return <Redirect to={`/auth?redirect=${this.props.location?.pathname}`} from={this.props.path?.toString()}></Redirect>;
    } else {
      console.log(`isLoggedIn [${this.props.isLoggedIn}] Path [${this.props.path}]`);
      if (this.props.isLoggedIn && this.validateUserGroup()) {
        return <Route {...this.props} />;
      } else {
        console.log("this.props.authInitRun");
        return (<Redirect to={`/auth`} from={this.props.path?.toString()} />);
      }
    }
  }

  abstract validateUserGroup(): Boolean;
}